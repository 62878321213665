import mixam from '../../../boot/mixam';
import defineComponent from '../../../../components/flight/lib/component';
import DataTable from '../../table/data-table-net/data-table-net.tsx';
import WithOrdersItemsMembers from '../with-orders-items-members';
import WithOrdersItems from '../with-order-items';
import WithSignDataRequest from '../../with-sign-data-request';
import { Breakpoints } from '../../table/data-table-net/StyledDataTableNet.tsx';
import {CELL_TYPES} from "../../table/data-table-net/DataTableConstants.ts";

export default defineComponent(WithOrdersItemsMembers, WithOrdersItems, AdminAccountsPayable, WithSignDataRequest);

function AdminAccountsPayable() {

    this.attributes({
        firstTimeUrl: `${mixam.reporterOrigin}/reporter/admin/api/shop/${mixam.shop.id}/orders/fulfilments/first`,
        url: `${mixam.reporterOrigin}/reporter/admin/api/shop/${mixam.shop.id}/orders/fulfilments`,
        dataTableSelector: '[data-type="data-table"]'
    });

    this.error = function (err) {
        if (console) {
            console.log(err);
        }
    };

    this.createSchema = function () {
        var cols = [];

        cols.push({
            title: '',
            type: "thumbnail",
            data:"front"
        });

        cols.push({
            title: "Order",
            type: "link",
            data:"href"
        });

        cols.push({
            title: "Username",
            type: "user",
            width: "9%",
            data: "user"
        });

        cols.push({
            title: "Status",
            type: "text",
            data: "status",
            width: "9%"
        });

        cols.push({
            title: "Created",
            type: "timebox",
            data:"time",
            defaultSort: true,
            sortOrder: 'desc'
        });

        cols.push({
            title: "Supplier",
            type: "text",
            data: "supplier",
            width: '200px',
            hide: Breakpoints.XXL,
        });

        cols.push({
            title: "Expected Cost",
            type: "number",
            decimal: 2,
            currency: true,
            data: "expectedCost",
            sum: true,
            hide: Breakpoints.MD,
        });

        cols.push({
            title: "Actual Cost",
            type: "CommandFulfilment",
            data: "actualCost",
            hide: Breakpoints.MD,
        });

        cols.push({
            title: "Packaging Cost",
            type: "number",
            data: "packagingCost",
            decimal: 2,
            currency: true,
            sum: true,
            hide: Breakpoints.MD,
        });

        cols.push({
            title: "In Dispute",
            type: "boolean",
            data: "fulfillmentDispute",
            hide: Breakpoints.LG,
        });

        cols.push({
            title: "Remark",
            type: "text",
            data: "fulfillmentRemark",
            hide: Breakpoints.LG,
        });

        cols.push({
            title: "Discrepancy",
            type: "string",
            data: "discrepancy",
            hide: Breakpoints.XL,
        });

        cols.push({
            title: "",
            type: CELL_TYPES.VIEW_PURCHASE_ORDER_MODAL,
            data: "purchaseOrderNumber",
            width: '100px'
        });

        cols.push({
            title: "",
            type: CELL_TYPES.RECONCILIATION_FORM_MODAL,
            width: '100px'
        });

        return cols;
    };

    /**
     * @override with-order-items
     */
    this.paint = function () {
        const data = {
            columns: this.createSchema(),
            response: this.normalizeListOrderEntities(this.data)
        };

        if (this.dataReadyFired) {
            window.dispatchEvent(new CustomEvent('uiDataUpdate', { detail: data }));
        } else {
            this.dataReadyFired = true;
            this.trigger("uiDataReady", data);
            this.clock();
        }
    };

    this.normalizeListOrderEntities = function (listOrderEntities) {
        return listOrderEntities.map(listOrderEntity =>
            this.normalizeListOrderItemEntity(listOrderEntity.item, listOrderEntity, listOrderEntity.index)
        );
    };

    this.normalizeListOrderItemEntity = function (listOrderItemEntity, listOrderEntity, index) {
        const normalizedEntity = $.extend(true, {}, listOrderItemEntity);

        normalizedEntity.id = listOrderEntity.itemId;

        normalizedEntity.orderId = listOrderEntity.id;
        normalizedEntity.itemId = listOrderEntity.itemId;

        normalizedEntity.front = {
            src: listOrderItemEntity.thumbnailUrl,
            href: `/orders/${listOrderEntity.id}/artwork`,
            target: "_blank"
        };

        normalizedEntity.href = {
            href: `/orders/${listOrderEntity.id}/artwork`,
            caption: listOrderEntity.caseNumber + (listOrderEntity.itemCount > 1 ? "/" + (index + 1) : ""),
            target: "_blank"
        };
        normalizedEntity.time = listOrderEntity.time;
        if (listOrderEntity.member) {
            normalizedEntity.user = {
                name: listOrderEntity.member.firstName + " " + listOrderEntity.member.lastName,
                email: listOrderEntity.member.email,
                phone: listOrderEntity.contact.phone,
                confirmedItemCount: listOrderEntity.member.confirmedItemCount,
                confirmedOrderCount: listOrderEntity.member.confirmedOrderCount,
                dateCreated: listOrderEntity.member.dateCreated,
                description: listOrderEntity.member.description,
                id: listOrderEntity.member.id,
                lastModifiedDate: listOrderEntity.member.lastModifiedDate,
                lastSigninDate: listOrderEntity.member.lastSigninDate,
                photo: listOrderEntity.member.photo,
                purchasesCount: listOrderEntity.member.purchasesCount,
                remark: listOrderEntity.member.remark,
                status: 1
            };
        } else {
            normalizedEntity.user = {
                name: listOrderEntity.contact.name,
                email: listOrderEntity.contact.email,
                phone: listOrderEntity.contact.phone
            };
        }
        normalizedEntity.status = listOrderEntity.orderStatus;

        if (listOrderItemEntity.fulfillment) {
            const fulfillment = listOrderItemEntity.fulfillment;
            const fulfillmentCost = fulfillment.cost || 0;

            normalizedEntity.expectedCost = fulfillmentCost;
            normalizedEntity.supplier = fulfillment.supplierName;
            normalizedEntity.packagingCost = listOrderItemEntity.fulfillment.packagingCost || 0;

            if (listOrderItemEntity.purchaseOrderExtendedReference) {
                const purchaseOrderExtendedReference = listOrderItemEntity.purchaseOrderExtendedReference;

                normalizedEntity.actualCost = purchaseOrderExtendedReference.actualCost;
                normalizedEntity.fulfillmentRemark = purchaseOrderExtendedReference.referenceNumber;
                normalizedEntity.fulfillmentDispute = purchaseOrderExtendedReference.fulfillmentDispute;
                normalizedEntity.purchaseOrderNumber = purchaseOrderExtendedReference.purchaseOrderNumber;

                const discrepancy = purchaseOrderExtendedReference.actualCost - fulfillmentCost;
                normalizedEntity.discrepancy = `${mixam.shop.currency.prefix}${discrepancy.toFixed(2)} (${(discrepancy / fulfillmentCost * 100).toFixed(2)}%)`;
            }
        }
        return normalizedEntity;
    };

    this.fulfilmentChange = function (event, data) {
        $.post(`/admin/api/fulfilment/${data.order}/${data.item}/update`, data).done((/*response*/) => true);
    };

    this.handleStompUpdate = function (stompData) {
        if (stompData.category === "FULFIL" && stompData.order.shopId === mixam.shop.id) {
            this.getData(); // fetch all data again. Inefficient but we need ListOrderEntity.class (from Reporter) and the STOMP contains a real Order.class object
            window.dispatchEvent(new CustomEvent('highlightDataTableRow', {detail: {rowToHighlightId: stompData.reason}}));
        }
    };

    this.after('initialize', function () {
        DataTable.attachTo(this.select('dataTableSelector'));

        this.on(document, "click.ask.for.notify.permissions", this.askForNotifyPermissions);
        this.on(document, "uiRequestFulfilmentStatusChange", this.fulfilmentChange);
        this.on(document, "uiDataImportComplete", () => {
            this.getData();
        });

        setTimeout(() => this.getData(), 60);

        try {
            this.subscribe(this.handleStompUpdate.bind(this)); // subscribe to the shopallmessages topic
        } catch (e) {

        }
    });
}
