import mixam from '../../boot/mixam';
import defineComponent from '../../../components/flight/lib/component';
import DataTable from '../table/data-table-net/data-table-net.tsx';
import problemCategory from "../constants/problemCategory";
import disputeRequestCategory from "../constants/disputeRequestCategory";
import disputeStatus from "../constants/disputeStatus";
import disputeCulprit from "../constants/disputeCulpritValues";
import disputeResolutions from "../constants/disputeResolutionValues";
import {Breakpoints} from "../table/data-table-net/StyledDataTableNet.tsx";

export default defineComponent(DisputesListManager);

function DisputesListManager() {

    this.attributes({
        url: "/api/admin/dispute/list",
        dataTableSelector: '[data-type="data-table"]'
    });

    this.createSchema = function () {
        const cols = [];

        cols.push({
            title: "",
            type: "thumbnail",
            data: "front"
        });

        cols.push({
            title: "Order",
            type: "link",
            data: "href"
        });

        cols.push({
            title: "Customer",
            type: "link",
            data: "member"
        });

        cols.push({
            title: "Created",
            type: "timebox",
            data: "dateCreated",
            hide: Breakpoints.MD
        });

        cols.push({
            title: "Closed",
            type: "timebox",
            data: "dateClosed",
            hide: Breakpoints.LG,
        });

        cols.push({
            title: "Status",
            type: "badge",
            data: "statusBadge",
            hide: Breakpoints.LG
        });

        cols.push({
            title: "Category",
            type: "text",
            data: "problemCategoryText",
            hide: Breakpoints.LG
        });

        cols.push({
            title: "Supplier",
            type: "text",
            data: "supplierName",
            hide: Breakpoints.XL
        });

        cols.push({
            title: "Supplier Item Cost",
            type: "number",
            decimal: 2,
            prepend: mixam.shop.currency.prefix,
            data: "itemSupplierCost",
            currency: true,
            hide: Breakpoints.XL
        });

        cols.push({
            title: "Item Value",
            type: "number",
            decimal: 2,
            prepend: mixam.shop.currency.prefix,
            data: "itemValue",
            currency: true,
            hide: Breakpoints.XL
        });

        cols.push({
            title: "Details",
            type: "showMoreText",
            data: "disputeText",
            width: "400px",
            hide: Breakpoints.XXL
        });

        cols.push({
            title: "Actual Amount Refunded",
            type: "dispute-credit-action",
            data: "actualCreditNoteAmountReceived"
        });

        cols.push({
            title: "Credit Note Received",
            type: "boolean",
            data: "creditNoteReceived",
            hide: Breakpoints.XXL
        });

        cols.push({
            title: "Supplier Refund Due",
            type: "boolean",
            data: "supplierRefundDue",
            hide: Breakpoints.XXL
        });

        cols.push({
            title: "Mixam Refund Amount",
            type: "number",
            data: "mixamRefundAmount",
            hide: Breakpoints.XXL,
            decimal: 2,
            currency: true,
        });

        cols.push({
            title: "Mixam Reprint Cost",
            type: "number",
            data: "mixamReprintCost",
            hide: Breakpoints.XXL,
            decimal: 2,
            currency: true,
        });

        cols.push({
            title: "Supplier Refund Amount",
            type: "number",
            data: "supplierRefundAmount",
            hide: Breakpoints.XXL,
            decimal: 2,
            currency: true,
        });

        cols.push({
            title: "Supplier Reprint Cost",
            type: "number",
            data: "supplierReprintCost",
            hide: Breakpoints.XXL,
            decimal: 2,
            currency: true,
        });

        cols.push({
            title: "Logistics Cost",
            type: "number",
            data: "logisticsCost",
            hide: Breakpoints.XXL,
            decimal: 2,
            currency: true,
        });

        cols.push({
            title: "Responsibility",
            type: "text",
            data: "disputeCulpritText",
            hide: Breakpoints.XXL
        });


        cols.push({
            title: "Remarks",
            type: "showMoreText",
            data: "remarksText",
            width: "400px",
            hide: Breakpoints.XXL
        });

        cols.push({
            title: "Closed Category",
            type: "text",
            data: "disputeCloseCategoryText",
            hide: Breakpoints.XXL
        });

        cols.push({
            title: "Date Closed",
            type: "text",
            data: "dateClosedText",
            hide: Breakpoints.XXL
        });

        cols.push({
            title: "Accepted By",
            type: "text",
            data: "acceptedByName",
            hide: Breakpoints.XXL
        });

        cols.push({
            title: "Closed By",
            type: "text",
            data: "closedByName",
            hide: Breakpoints.XXL
        });

        cols.push({
            title: "Resolution",
            type: "text",
            data: "disputeResolutionsText",
            hide: Breakpoints.XXL
        });

        cols.push({
            title: "Action",
            type: "dispute-action",
            data: "status",
            sortable: false
        });

        return cols;
    };

    this.paint = function () {
        const data = {
            columns: this.createSchema(),
            fileName: this.attr.url.split("/").pop(),
            response: this.normalize(this.data.list),
            "class": 'table-striped table-order-list',
            "default-sort": {
                "dateCreated": -1
            }
        };

        if (this.dataReadyFired) {
            window.dispatchEvent(new CustomEvent('uiDataUpdate', { detail: data }));
        } else {
            this.dataReadyFired = true;
            this.trigger("uiDataReady", data);
        }
    };

    this.normalize = function (list) {
        return list.map(x => this.normalizeMessage(x));
    };

    this.normalizeMessage = function (normalizeMessage) {
        const result = $.extend(true, {}, normalizeMessage);

        result.override = result.override || {};
        result.override.td = result.override.td || {};
        result.front = {
            src: result.thumbnailUrl,
            href: `/orders/${result.orderId}/artwork`,
            title: result.caseNumber,
            target: "_blank"
        };
        result.override.td.front = "orient-" + result.thumbnailOrientaion;
        result.href = {
            href: `/orders/${result.orderId}/artwork`,
            title: result.caseNumber,
            caption: result.caseNumber,
            target: "_blank"
        };
        result.member = {
            href: `/member/${result.memberId}`,
            caption: result.memberName,
            target: "_blank"
        };
        result.problemCategoryText = problemCategory[result.problemCategory];
        result.disputeRequestCategory = disputeRequestCategory[result.requestCategory];
        result.disputeCloseCategoryText = mixam.disputeRequestCategoryValues[result.disputeCloseCategory];
        result.disputeCulpritText = disputeCulprit[result.culprit];
        result.disputeResolutionsText = disputeResolutions[result.resolvedActionType];
        result.dateClosedText = mixam.dateToDateTimeString(result.dateClosed);
        result.problemDetailsText = result.problemDetails;
        result.disputeText = {
            lines: 3,
            text: result.problemDetails
        };
        result.remarksText = {
            lines: 3,
            text: result.remarks ? result.remarks : ''
        };
        result.reprintOrderLink = {
            href: `/orders/${result.reprintOrderId}/`,
            title: result.reprintCaseNumber,
            target: "_blank"
        };
        result.statusBadge = {
            label: disputeStatus.fromValue(result.status),
            colour: disputeStatus.getBadgeColour(result.status)
        };
        result.supplierRefundDue = result.supplierRefundAmount > 0.0;
        return result;
    };


    this.getData = function () {
        $.getJSON(this.attr.url)
            .done(data => {
                this.data = data;
                this.paint();
                setTimeout(() => this.getData(), 1000 * 60 * 15);
            })
            .fail((err) => this.trigger("log", {message: err}));
    };

    /**
     * subscribe to STOMP service that notify us about changes
     * in a specific invoice
     */
    this.subscribe = function (onMessage) {
        this.trigger("log", {message: ["Subscribe to stomp channel alldisputechanel"], title: "CxmlManager.subscribe"});
        this.stomp = Stomp.client(mixam.stompServiceUrl);

        this.stomp.debug = (...args) => {
            if (args.join('').indexOf('Whoops! Lost connection to')  !== -1) {
                setTimeout(() => this.subscribe(onMessage), 10);
            }
            this.trigger("log", {message: args, title: "CxmlManager.subscribe"});
        };

        this.stomp.connect(mixam.stompWebUser, mixam.stompWebPass, () => {
            this.stomp.subscribe("/topic/alldisputechanel", (d) => {
                const p = JSON.parse(d.body);
                onMessage(p);
            });
        }, (err) => this.trigger("log", err), '/');
    };

    this.updateMessageLine = function (data) {
        if (!this.data) {
            return;
        }

        let verb = data.type;
        const message = data.data;
        if (message.shopId !== mixam.shop.id) {
            return;
        }

        const index = this.data.list.map(x => x.id).indexOf(message.id);

        if (verb === "UPDATE" || verb === "INSERT") {
            if (index !== -1) {
                this.data.list[index] = message;
            } else {
                this.data.list.unshift(message);
            }

        } else if (verb === "DELETE" && index !== -1) {
            this.data.list.splice(index, 1);
        }

        this.paint();
        requestAnimationFrame(() => $("#PK" + message.id).hilightTableLine());
    };
    this.after('initialize', function () {
        DataTable.attachTo(this.select('dataTableSelector'));
        setTimeout(() => this.getData(), 10);
        this.subscribe(d => this.updateMessageLine(d));
    });
}
