import mixam from '../../../boot/mixam';
import defineComponent from '../../../../components/flight/lib/component';
import DataTable from '../../table/data-table-net/data-table-net.tsx';
import axios from "axios";

export default defineComponent(ShopifyFulfillments);

function ShopifyFulfillments() {

    this.attributes({
        url: "/api/admin/shopify/fulfillments",
        dataTableSelector: '[data-type="data-table"]'
    });

    this.createSchema = function () {
        const cols = [];

        cols.push({
            title: "Received Date",
            type: "date",
            data: "createdDate",
            defaultSort: true,
            sortOrder: 'desc'
        });
        cols.push({
            title: "Kind",
            type: "text",
            data: "kind",
        });
        cols.push({
            title: "Shopify Store URL",
            type: "text",
            data: "shopifyStoreUrl",
        });
        cols.push({
            title: "Status",
            type: "text",
            data: "status",
        });
        cols.push({
            title: "Destination",
            type: "api-log-modal",
            data: "requestBody",
        });
        cols.push({
            title: "Mixam Publisher Username",
            type: "text",
            data: "publisherUsername",
        });
        cols.push({
            title: "Processing Started Date",
            type: "date",
            data: "processingStartedDate",
        });
        cols.push({
            title: "Print On Demand Order",
            type: "link",
            data: "podOrderUrl",
        });
        cols.push({
            title: "Shopify Fulfillment Order ID",
            type: "text",
            data: "id",
        });

        return cols;
    };


    this.paint = function () {
        const data = {
            columns: this.createSchema(),
            fileName: this.attr.url.split("/").pop(),
            response: this.normalize(this.data)
        };

        if (this.dataReadyFired) {
            window.dispatchEvent(new CustomEvent('uiDataUpdate', { detail: data }));
        } else {
            this.dataReadyFired = true;
            this.trigger("uiDataReady", data);
        }
    };

    this.normalize = function (data) {
        return data.map(row => {
            return {
                ...row,
                createdDate: row.createdDateAsDateTimestamp.timestamp,
                processingStartedDate: row.processingStartedDateAsDateTimestamp.timestamp,
                shopifyStoreUrl: row.apiLink.namespace,
                podOrderUrl: row.printOnDemandOrderId ? {
                    href: `${mixam.secureDomain}/orders/${row.printOnDemandOrderId}`,
                    target: '_blank',
                    caption: `${mixam.secureDomain}/orders/${row.printOnDemandOrderId}`
                } : null,
                requestBody: row.fulfillmentOrderDestination
            };
        });
    };


    this.getData = function () {
        $.getJSON(this.attr.url)
            .done(data => {
                this.data = data;
                this.paint();
            })
            .fail((err) => this.trigger("log", {message: err}));
    };

    this.handleProcessFulfillments = function () {
        window.dispatchEvent(
            new CustomEvent(
                'showFixedMessage',
                { detail: { message: { type: 'info', body: 'Processing Fulfillments', interval: 2000}}}
            )
        );
        axios.post('/api/admin/shopify/fulfillments/trigger')
            .then(() => {
                window.dispatchEvent(
                    new CustomEvent(
                        'showFixedMessage',
                        { detail: { message: { type: 'success', body: 'Finished Processing Fulfillments', interval: 3000}}}
                    )
                );
            }).catch(() => {
            window.dispatchEvent(
                new CustomEvent(
                    'showFixedMessage',
                    { detail: { message: { type: 'error', body: 'Error Processing Fulfillments', interval: 3000}}}
                )
            );
        });
    };

    this.after('initialize', function () {
        document.getElementById('fulfillment-process-btn').addEventListener('click', this.handleProcessFulfillments);

        DataTable.attachTo(this.select('dataTableSelector'));
        setTimeout(() => this.getData(), 10);
    });
}
