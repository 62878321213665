export var Application;
(function (Application) {
    Application["SHOP"] = "SHOP";
    Application["PRICE_CALCULATOR"] = "PRICE_CALCULATOR";
    Application["CHECKOUT"] = "CHECKOUT";
    Application["ACCOUNT"] = "ACCOUNT";
    Application["ASSETS"] = "ASSETS";
})(Application || (Application = {}));
export var MODE;
(function (MODE) {
    MODE["INLINE"] = "INLINE";
    MODE["ADMIN"] = "ADMIN";
})(MODE || (MODE = {}));
