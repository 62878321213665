var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from "react";
import { useQuery } from "react-query";
import ChangeLogsApiClient from "./ChangeLogsApiClient";
import LogContent from "./LogContent";
import LogList from "./LogList";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import DateRangePicker from '../util/DateRangePicker';
var animatedComponents = makeAnimated();
var PAGE_SIZE_OPTIONS = [
    { label: '10 per page', value: 10 },
    { label: '20 per page', value: 20 },
    { label: '50 per page', value: 50 },
    { label: '100 per page', value: 100 }
];
var selectStyles = {
    control: function (baseStyles, state) { return (__assign(__assign({}, baseStyles), { borderColor: state.isFocused ? 'rgb(159.5, 211.5, 209)' : baseStyles.borderColor, boxShadow: state.isFocused ? '0 0 0 0.25rem rgba(64, 168, 163, 0.25)' : baseStyles.boxShadow, '&:hover': {
            borderColor: 'rgb(159.5, 211.5, 209)'
        } })); },
    option: function (baseStyles, state) { return (__assign(__assign({}, baseStyles), { backgroundColor: state.isSelected
            ? 'rgb(64, 168, 163)' // selected option - darker turquoise
            : state.isFocused
                ? 'rgba(159.5, 211.5, 209, 0.25)' // hovered option - lighter turquoise
                : 'white', color: state.isSelected ? 'white' : 'inherit', '&:active': {
            backgroundColor: 'rgb(64, 168, 163)' // clicking color
        } })); },
    multiValue: function (baseStyles) { return (__assign(__assign({}, baseStyles), { backgroundColor: 'rgba(159.5, 211.5, 209, 0.25)' // light turquoise for selected tags
     })); },
    multiValueLabel: function (baseStyles) { return (__assign(__assign({}, baseStyles), { color: 'rgb(64, 168, 163)' // dark turquoise for tag text
     })); }
};
export default function ApiChangeLogs(_a) {
    var shopId = _a.shopId, logId = _a.logId, logType = _a.logType, logObjectId = _a.logObjectId;
    var _b = useState(null), selectedElement = _b[0], setSelectedElement = _b[1];
    var _c = useState(0), page = _c[0], setPage = _c[1];
    var _d = useState(20), size = _d[0], setSize = _d[1];
    var _e = useState(null), startDate = _e[0], setStartDate = _e[1];
    var _f = useState(null), endDate = _f[0], setEndDate = _f[1];
    var _g = useState(logType ? [logType] : []), selectedLogTypes = _g[0], setSelectedLogTypes = _g[1];
    var _h = useState([]), logTypeOptions = _h[0], setLogTypeOptions = _h[1];
    var changeLogsService = new ChangeLogsApiClient();
    var _j = useQuery(['changeLogs', logObjectId, logType, page, size, selectedLogTypes, startDate, endDate], function () {
        if (logObjectId && logType) {
            return changeLogsService.getChangeLogsByRecordIdAndLogType(logObjectId, logType, {
                page: page,
                size: size,
                startDate: startDate === null || startDate === void 0 ? void 0 : startDate.toISOString(),
                endDate: endDate === null || endDate === void 0 ? void 0 : endDate.toISOString()
            });
        }
        else if (logObjectId) {
            return changeLogsService.getChangeLogsByRecordId(logObjectId, {
                page: page,
                size: size,
                startDate: startDate === null || startDate === void 0 ? void 0 : startDate.toISOString(),
                endDate: endDate === null || endDate === void 0 ? void 0 : endDate.toISOString()
            });
        }
        else {
            return changeLogsService.getAllChangeLogs({
                page: page,
                size: size,
                logTypes: selectedLogTypes,
                startDate: startDate === null || startDate === void 0 ? void 0 : startDate.toISOString(),
                endDate: endDate === null || endDate === void 0 ? void 0 : endDate.toISOString()
            });
        }
    }, {
        refetchOnMount: true,
        refetchOnWindowFocus: false,
        staleTime: 500,
        onSuccess: function (data) {
            if (!(data === null || data === void 0 ? void 0 : data.content) || data.content.length === 0) {
                setSelectedElement(null);
            }
        }
    }), data = _j.data, isLoading = _j.isLoading, error = _j.error;
    var handleDateChange = function (start, end) {
        setStartDate(start);
        setEndDate(end);
        setPage(0);
    };
    var types = useQuery('changeLogTypes', function () { return changeLogsService.getChangeLogTypes(); }, {
        onSuccess: function (data) { return setLogTypeOptions(data); }
    }).data;
    return (React.createElement("div", { className: "mt-4" },
        React.createElement("div", { className: "row mb-3" },
            React.createElement("div", { className: "col-md-4" }, !logObjectId && (React.createElement(Select, { closeMenuOnSelect: false, components: animatedComponents, isMulti: true, options: logTypeOptions, value: logTypeOptions.filter(function (option) {
                    return selectedLogTypes.includes(option.value);
                }), onChange: function (selected) {
                    setSelectedLogTypes(selected.map(function (option) { return option.value; }));
                    setPage(0);
                }, styles: selectStyles, className: "basic-multi-select", classNamePrefix: "select", placeholder: "Select log types..." }))),
            React.createElement("div", { className: "col-md-4" },
                React.createElement(DateRangePicker, { startDate: startDate, endDate: endDate, onDateChange: handleDateChange, placeholder: "Select date range..." })),
            React.createElement("div", { className: "col-md-4" },
                React.createElement(Select, { options: PAGE_SIZE_OPTIONS, value: PAGE_SIZE_OPTIONS.find(function (option) { return option.value === size; }), onChange: function (selected) {
                        if (selected) {
                            setSize(selected.value);
                            setPage(0);
                        }
                    }, styles: selectStyles, className: "basic-select", classNamePrefix: "select", placeholder: "Select page size..." }))),
        React.createElement("div", { className: "row" },
            React.createElement("div", { className: "col-md-4 border-light border-1 border-end border-top p-0" }, isLoading ? (React.createElement("div", { className: "p-3 text-center" }, "Loading...")) : error ? (React.createElement("div", { className: "p-3 text-center text-danger" }, "Error loading change logs")) : !(data === null || data === void 0 ? void 0 : data.content) || data.content.length === 0 ? (React.createElement("div", { className: "p-3 text-center" }, "No records found")) : (React.createElement(React.Fragment, null,
                React.createElement(LogList, { elements: data.content, onSelectElement: setSelectedElement }),
                React.createElement("div", { className: "mt-3" },
                    data.totalPages > 1 && (React.createElement("nav", { "aria-label": "Change logs navigation" },
                        React.createElement("ul", { className: "pagination justify-content-center" },
                            React.createElement("li", { className: "page-item ".concat(data.first ? 'disabled' : '') },
                                React.createElement("button", { className: "page-link", onClick: function () { return setPage(function (p) { return Math.max(0, p - 1); }); }, disabled: data.first }, "Previous")),
                            React.createElement("li", { className: "page-item disabled" },
                                React.createElement("span", { className: "page-link" },
                                    "Page ",
                                    data.number + 1,
                                    " of ",
                                    data.totalPages)),
                            React.createElement("li", { className: "page-item ".concat(data.last ? 'disabled' : '') },
                                React.createElement("button", { className: "page-link", onClick: function () { return setPage(function (p) { return p + 1; }); }, disabled: data.last }, "Next"))))),
                    React.createElement("div", { className: "text-center text-muted small" },
                        "Total records: ",
                        data.totalElements))))),
            React.createElement("div", { className: "col-md-8 border-light border-1 border-start border-top" },
                React.createElement(LogContent, { element: selectedElement })))));
}
