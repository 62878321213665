var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState } from 'react';
import { Spinner, Alert, Button, Form, Row, Col, Card } from 'react-bootstrap';
import { Formik, Field, Form as FormikForm, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import ConfirmationModal from "./ConfirmationModal";
var CustomSizeMetaDataEditor = function (_a) {
    var productId = _a.productId, subProductId = _a.subProductId, santaType = _a.santaType;
    var _b = useState(false), isLoading = _b[0], setLoading = _b[1];
    var _c = useState(null), error = _c[0], setError = _c[1];
    var _d = useState(null), successMessage = _d[0], setSuccessMessage = _d[1];
    var _e = useState(null), metadataDocumentWrapper = _e[0], setMetadataDocumentWrapper = _e[1];
    var _f = useState(false), useCustom = _f[0], setUseCustom = _f[1];
    var _g = useState(false), showConfirmationModal = _g[0], setShowConfirmationModal = _g[1];
    useEffect(function () {
        setLoading(true);
        axios
            .get("/api/admin/metadata/product/custom-size/products/".concat(productId, "/sub-products/").concat(subProductId, "/santa-types/").concat(santaType))
            .then(function (response) {
            setMetadataDocumentWrapper(response.data);
            if (subProductId !== 0) {
                setUseCustom(response.data.productMetadataDocument !== null);
            }
            setLoading(false);
        })
            .catch(function () {
            setLoading(false);
            setError('Failed to load custom sizes metadata document.');
        });
    }, [productId, subProductId, santaType]);
    if (isLoading) {
        return React.createElement(Spinner, { animation: "grow", variant: "primary" });
    }
    if (!metadataDocumentWrapper) {
        return React.createElement(Alert, { variant: "danger" }, "No Custom Sizes Metadata available");
    }
    var validationSchema = Yup.object({
        minWidth: Yup.number()
            .min(0, 'Minimum Width must be greater than or equal to 0')
            .integer('Minimum Width must be a whole number')
            .required('Minimum Width is required')
            .test('test-min-width-less-than-equal-max-width', 'Minimum Width must be less than or equal to Maximum Width', function checkMinWidth(minWidth) {
            var maxWidth = this.parent.maxWidth;
            return minWidth <= maxWidth;
        }),
        maxWidth: Yup.number()
            .min(1, 'Maximum Width must be greater than or equal to 1')
            .integer('Maximum Width must be a whole number')
            .required('Maximum Width is required')
            .test('test-max-width-greater-than-equal-min-width', 'Maximum Width must be greater than or equal to Minimum Width', function checkMaxWidth(maxWidth) {
            var minWidth = this.parent.minWidth;
            return maxWidth >= minWidth;
        }),
        minHeight: Yup.number()
            .min(0, 'Minimum Height must be greater than or equal to 0')
            .integer('Minimum Height must be a whole number')
            .required('Minimum Height is required')
            .test('test-min-height-less-than-equal-max-height', 'Minimum Height must be less than or equal to Maximum Height', function checkMinHeight(minHeight) {
            var maxHeight = this.parent.maxHeight;
            return minHeight <= maxHeight;
        }),
        maxHeight: Yup.number()
            .min(1, 'Maximum Height must be greater than or equal to 1')
            .integer('Maximum Height must be a whole number')
            .required('Maximum Height is required')
            .test('test-max-height-greater-than-equal-min-height', 'Maximum Height must be greater than or equal to Minimum Height', function checkMaxHeight(maxHeight) {
            var minHeight = this.parent.minHeight;
            return maxHeight >= minHeight;
        }),
    });
    var saveCustomSizesMetadata = function (values, setSubmitting) {
        var _a;
        setLoading(true);
        setError(null);
        setSuccessMessage(null);
        var activeDocument = useCustom
            ? metadataDocumentWrapper === null || metadataDocumentWrapper === void 0 ? void 0 : metadataDocumentWrapper.productMetadataDocument
            : metadataDocumentWrapper === null || metadataDocumentWrapper === void 0 ? void 0 : metadataDocumentWrapper.defaultProductMetadataDocument;
        var newDocument = activeDocument
            ? __assign({}, activeDocument) : {
            shopId: (_a = metadataDocumentWrapper === null || metadataDocumentWrapper === void 0 ? void 0 : metadataDocumentWrapper.defaultProductMetadataDocument) === null || _a === void 0 ? void 0 : _a.shopId,
            productId: productId,
            subProductId: subProductId,
            santaType: santaType,
        };
        // Update the newDocument with values from the form
        var updatedDocument = __assign(__assign({}, newDocument), values);
        var saveRequest = {
            useDefault: !useCustom,
            customSizeMetadataDocument: __assign(__assign({}, newDocument), { customSizeMetadata: updatedDocument }),
        };
        axios
            .post("/api/admin/metadata/product/custom-size/products/".concat(productId, "/sub-products/").concat(subProductId, "/santa-types/").concat(santaType), saveRequest)
            .then(function (response) {
            setMetadataDocumentWrapper(response.data);
            setLoading(false);
            setSubmitting(false);
            setSuccessMessage('Custom Sizes Metadata saved successfully!');
        })
            .catch(function (error) {
            var _a;
            setLoading(false);
            setSubmitting(false);
            setError("Failed to save Custom Sizes document: ".concat(((_a = error.response) === null || _a === void 0 ? void 0 : _a.data) || error.message));
        });
    };
    var handleSave = function (values, _a) {
        var setSubmitting = _a.setSubmitting;
        if (!useCustom && subProductId !== 0) {
            setShowConfirmationModal(true);
        }
        else {
            saveCustomSizesMetadata(values, setSubmitting);
        }
    };
    var handleCopyFromDefault = function () {
        var _a;
        if (metadataDocumentWrapper === null || metadataDocumentWrapper === void 0 ? void 0 : metadataDocumentWrapper.defaultProductMetadataDocument) {
            var defaultData = metadataDocumentWrapper.defaultProductMetadataDocument;
            var updatedCustomDocument = __assign(__assign({}, metadataDocumentWrapper.productMetadataDocument || {
                shopId: (_a = metadataDocumentWrapper === null || metadataDocumentWrapper === void 0 ? void 0 : metadataDocumentWrapper.defaultProductMetadataDocument) === null || _a === void 0 ? void 0 : _a.shopId,
                productId: productId,
                subProductId: subProductId,
                santaType: santaType
            }), { customSizeMetadata: {
                    enabled: defaultData.customSizeMetadata.enabled,
                    minWidth: defaultData.customSizeMetadata.minWidth,
                    maxWidth: defaultData.customSizeMetadata.maxWidth,
                    minHeight: defaultData.customSizeMetadata.minHeight,
                    maxHeight: defaultData.customSizeMetadata.maxHeight
                } });
            setMetadataDocumentWrapper(__assign(__assign({}, metadataDocumentWrapper), { productMetadataDocument: updatedCustomDocument }));
        }
    };
    var activeDocument = useCustom ? metadataDocumentWrapper === null || metadataDocumentWrapper === void 0 ? void 0 : metadataDocumentWrapper.productMetadataDocument : metadataDocumentWrapper === null || metadataDocumentWrapper === void 0 ? void 0 : metadataDocumentWrapper.defaultProductMetadataDocument;
    var initialValues = {
        enabled: (activeDocument === null || activeDocument === void 0 ? void 0 : activeDocument.customSizeMetadata.enabled) || false,
        minWidth: (activeDocument === null || activeDocument === void 0 ? void 0 : activeDocument.customSizeMetadata.minWidth) || 0,
        maxWidth: (activeDocument === null || activeDocument === void 0 ? void 0 : activeDocument.customSizeMetadata.maxWidth) || 100,
        minHeight: (activeDocument === null || activeDocument === void 0 ? void 0 : activeDocument.customSizeMetadata.minHeight) || 0,
        maxHeight: (activeDocument === null || activeDocument === void 0 ? void 0 : activeDocument.customSizeMetadata.maxHeight) || 100,
    };
    return (React.createElement(Formik, { initialValues: initialValues, validationSchema: validationSchema, enableReinitialize: true, onSubmit: handleSave }, function (_a) {
        var isSubmitting = _a.isSubmitting, values = _a.values, setFieldValue = _a.setFieldValue;
        return (React.createElement(React.Fragment, null,
            React.createElement(Card, { className: "p-4 shadow-sm bg-light" },
                React.createElement(FormikForm, null,
                    React.createElement("h5", { className: "mb-4" }, "Custom Sizes Metadata"),
                    successMessage && (React.createElement(Alert, { variant: "success", onClose: function () { return setSuccessMessage(null); }, dismissible: true }, successMessage)),
                    error && (React.createElement(Alert, { variant: "danger", onClose: function () { return setError(null); }, dismissible: true }, error)),
                    subProductId !== 0 && (React.createElement(Form.Group, { className: "mb-4" },
                        React.createElement(Form.Check, { type: "radio", label: "Use Default Metadata", checked: !useCustom, onChange: function () { return setUseCustom(false); } }),
                        React.createElement(Form.Check, { type: "radio", label: "Customize Metadata", checked: useCustom, onChange: function () { return setUseCustom(true); } }),
                        React.createElement("hr", null),
                        useCustom && (React.createElement(Button, { className: "mt-1 btn-sm", variant: "secondary", onClick: handleCopyFromDefault }, "Copy Default Values")))),
                    React.createElement(Row, { className: "mt-4" },
                        React.createElement(Col, { md: 6 },
                            React.createElement(Form.Group, null,
                                React.createElement(Form.Label, null,
                                    React.createElement("strong", null, "Enable Custom Sizes")),
                                React.createElement(Form.Check, { type: "switch", id: "enabled-switch", label: values.enabled ? 'Yes' : 'No', checked: values.enabled, onChange: function () { return setFieldValue('enabled', !values.enabled); }, disabled: subProductId !== 0 && !useCustom })))),
                    values.enabled && (React.createElement(React.Fragment, null,
                        React.createElement(Row, { className: "mt-4" },
                            React.createElement(Col, { md: 6 },
                                React.createElement(Form.Group, { controlId: "minWidth" },
                                    React.createElement(Form.Label, null,
                                        React.createElement("strong", null, "Minimum Width")),
                                    React.createElement(Field, { type: "number", name: "minWidth", value: values.minWidth, onChange: function (e) {
                                            return setFieldValue('minWidth', Number(e.target.value));
                                        }, min: 0, inputMode: "numeric", pattern: "[0-9]*", className: "form-control", disabled: subProductId !== 0 && !useCustom || !values.enabled }),
                                    React.createElement(ErrorMessage, { name: "minWidth", component: "div", className: "text-danger small mt-1" }))),
                            React.createElement(Col, { md: 6 },
                                React.createElement(Form.Group, { controlId: "maxWidth" },
                                    React.createElement(Form.Label, null,
                                        React.createElement("strong", null, "Maximum Width")),
                                    React.createElement(Field, { type: "number", name: "maxWidth", value: values.maxWidth, onChange: function (e) {
                                            return setFieldValue('maxWidth', Number(e.target.value));
                                        }, min: 0, inputMode: "numeric", pattern: "[0-9]*", className: "form-control", disabled: subProductId !== 0 && !useCustom || !values.enabled }),
                                    React.createElement(ErrorMessage, { name: "maxWidth", component: "div", className: "text-danger small mt-1" })))),
                        React.createElement(Row, { className: "mt-4" },
                            React.createElement(Col, { md: 6 },
                                React.createElement(Form.Group, { controlId: "minHeight" },
                                    React.createElement(Form.Label, null,
                                        React.createElement("strong", null, "Minimum Height")),
                                    React.createElement(Field, { type: "number", name: "minHeight", value: values.minHeight, onChange: function (e) {
                                            return setFieldValue('minHeight', Number(e.target.value));
                                        }, min: 0, inputMode: "numeric", pattern: "[0-9]*", className: "form-control", disabled: subProductId !== 0 && !useCustom || !values.enabled }),
                                    React.createElement(ErrorMessage, { name: "minHeight", component: "div", className: "text-danger small mt-1" }))),
                            React.createElement(Col, { md: 6 },
                                React.createElement(Form.Group, { controlId: "maxHeight" },
                                    React.createElement(Form.Label, null,
                                        React.createElement("strong", null, "Maximum Height")),
                                    React.createElement(Form.Control, { type: "number", name: "maxHeight", value: values.maxHeight, onChange: function (e) {
                                            return setFieldValue('maxHeight', Number(e.target.value));
                                        }, min: 0, inputMode: "numeric", pattern: "[0-9]*", className: "form-control", disabled: subProductId !== 0 && !useCustom || !values.enabled }),
                                    React.createElement(ErrorMessage, { name: "maxHeight", component: "div", className: "text-danger small mt-1" })))))),
                    React.createElement(Button, { type: "submit", disabled: isSubmitting, variant: "primary", className: "mt-4 px-4 py-2" }, "Save Changes"))),
            React.createElement(ConfirmationModal, { show: showConfirmationModal, onHide: function () { return setShowConfirmationModal(false); }, onConfirm: function () {
                    setShowConfirmationModal(false);
                    saveCustomSizesMetadata(initialValues, function () { });
                } })));
    }));
};
export default CustomSizeMetaDataEditor;
