var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useState } from 'react';
import { Row, Col, Spinner, Alert, Tab, Nav } from 'react-bootstrap';
import { QueryClient, QueryClientProvider } from 'react-query';
import axios from 'axios';
import { MetaDataType, ProductMetaDataTypeMap } from './types';
import CopiesMetadataEditor from './Components/CopiesMetaDataEditor';
import CustomSizeMetaDataEditor from './Components/CustomSizeMetaDataEditor';
import OrientationMetaDataEditor from './Components/OrientationMetaDataEditor';
import StandardSizesMetaDataEditor from './Components/StandardSizesMetaDataEditor';
import LaminationsMetadataEditor from "./Components/LaminationsMetadataEditor";
import SubstrateTypesMetadataEditor from "./Components/SubstrateTypesMetaDataEditor";
import ColoursMetaDataEditor from "./Components/ColoursMetaDataEditor";
import FoilingMetaDataEditor from "./Components/FoilingMetaDataEditor";
import CustomsExportMetaDataEditor from "./Components/CustomsExportMetaDataEditor";
import PreDrilledHolesMetaDataEditor from "./Components/PreDrilledHolesMetaDataEditor";
import BoundMetaDataEditor from "./Components/BoundMetaDataEditor";
import ApiChangeLogs from "../../../../../assets/react/change-logs/ApiChangeLogs";
import ShrinkWrapMetaDataEditor from "./Components/ShrinkWrapMetaDataEditor";
import ChangeLogsApiClient from '../../../../../assets/react/change-logs/ChangeLogsApiClient';
var queryClient = new QueryClient();
var ProductMetaDataEditor = function (props) {
    var _a, _b, _c, _d, _e, _f, _g;
    var _h = useState(true), isDataLoading = _h[0], setDataLoading = _h[1];
    var _j = useState(null), productMetaData = _j[0], setProductMetaData = _j[1];
    var _k = useState([]), metaDataTypes = _k[0], setMetaDataTypes = _k[1];
    var _l = useState(null), catalogue = _l[0], setCatalogue = _l[1];
    var _m = useState([]), logTypeOptions = _m[0], setLogTypeOptions = _m[1];
    var changeLogsService = new ChangeLogsApiClient();
    useEffect(function () {
        var fetchData = function () { return __awaiter(void 0, void 0, void 0, function () {
            var error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        setDataLoading(true);
                        return [4 /*yield*/, Promise.all([getProductMetadata(), getCatalogueData()])];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 2:
                        error_1 = _a.sent();
                        console.error('Error fetching data', error_1);
                        return [3 /*break*/, 4];
                    case 3:
                        setDataLoading(false);
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        fetchData();
    }, [props.productId, props.subProductId, props.santaType]);
    useEffect(function () {
        var fetchLogTypes = function () { return __awaiter(void 0, void 0, void 0, function () {
            var types, error_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, changeLogsService.getChangeLogTypes()];
                    case 1:
                        types = _a.sent();
                        setLogTypeOptions(types);
                        return [3 /*break*/, 3];
                    case 2:
                        error_2 = _a.sent();
                        console.error('Error fetching log types:', error_2);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        fetchLogTypes();
    }, []);
    var getProductMetadata = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, error_3;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, axios.get("/api/products/".concat(props.productId, "/").concat(props.subProductId, "?santaType=").concat(props.santaType))];
                case 1:
                    response = _b.sent();
                    setProductMetaData(response.data);
                    setMetaDataTypes(ProductMetaDataTypeMap[(_a = response.data.initialSpecification) === null || _a === void 0 ? void 0 : _a.product] || []);
                    return [3 /*break*/, 3];
                case 2:
                    error_3 = _b.sent();
                    console.log('Error fetching product metadata', error_3);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var getCatalogueData = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, error_4;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, axios.get("/api/public/catalogue")];
                case 1:
                    response = _a.sent();
                    setCatalogue(response.data);
                    return [3 /*break*/, 3];
                case 2:
                    error_4 = _a.sent();
                    console.log('Error fetching catalogue data', error_4);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(QueryClientProvider, { client: queryClient },
        React.createElement(Row, null,
            React.createElement(Col, null,
                isDataLoading && React.createElement(Spinner, { animation: "grow", variant: "primary" }),
                !isDataLoading && !productMetaData && React.createElement(Alert, { variant: "danger" }, "Error retrieving product metadata"))),
        !isDataLoading && productMetaData && catalogue && (React.createElement(Tab.Container, { id: "metadata-options", defaultActiveKey: "copies" },
            React.createElement(Row, null,
                React.createElement(Col, { xs: 3, lg: 2 },
                    React.createElement(Nav, { variant: "pills", className: "flex-column" },
                        metaDataTypes.some(function (metaData) { return metaData.type === MetaDataType.COPIES; }) && (React.createElement(Nav.Item, null,
                            React.createElement(Nav.Link, { eventKey: "copies" }, "Copies"))),
                        metaDataTypes.some(function (metaData) { return metaData.type === MetaDataType.CUSTOM_SIZE; }) && (React.createElement(Nav.Item, null,
                            React.createElement(Nav.Link, { eventKey: "custom-size" }, "Custom Size"))),
                        metaDataTypes.some(function (metaData) { return metaData.type === MetaDataType.ORIENTATION; }) && (React.createElement(Nav.Item, null,
                            React.createElement(Nav.Link, { eventKey: "orientation" }, "Orientation"))),
                        metaDataTypes.some(function (metaData) { return metaData.type === MetaDataType.COLOURS; }) && (React.createElement(Nav.Item, null,
                            React.createElement(Nav.Link, { eventKey: "colours" }, "Colours"))),
                        metaDataTypes.some(function (metaData) { return metaData.type === MetaDataType.STANDARD_SIZES; }) && (React.createElement(Nav.Item, null,
                            React.createElement(Nav.Link, { eventKey: "standardSizes" }, "Standard Sizes"))),
                        metaDataTypes.some(function (metaData) { return metaData.type === MetaDataType.SUBSTRATE_TYPES; }) && (React.createElement(Nav.Item, null,
                            React.createElement(Nav.Link, { eventKey: "substrate-types" }, "Substrates"))),
                        metaDataTypes.some(function (metaData) { return metaData.type === MetaDataType.LAMINATIONS; }) && (React.createElement(Nav.Item, null,
                            React.createElement(Nav.Link, { eventKey: "lamination" }, "Lamination"))),
                        metaDataTypes.some(function (metaData) { return metaData.type === MetaDataType.FOILING; }) && (React.createElement(Nav.Item, null,
                            React.createElement(Nav.Link, { eventKey: "foiling" }, "Foiling"))),
                        metaDataTypes.some(function (metaData) { return metaData.type === MetaDataType.CUSTOMS_EXPORT_DATA; }) && (React.createElement(Nav.Item, null,
                            React.createElement(Nav.Link, { eventKey: "customs-export-data" }, "Customs Export Data"))),
                        metaDataTypes.some(function (metaData) { return metaData.type === MetaDataType.PRE_DRILLED_HOLES; }) && (React.createElement(Nav.Item, null,
                            React.createElement(Nav.Link, { eventKey: "pre-drilled-holes" }, "Pre Drilled Holes"))),
                        metaDataTypes.some(function (metaData) { return metaData.type === MetaDataType.BOUND; }) && (React.createElement(Nav.Item, null,
                            React.createElement(Nav.Link, { eventKey: "bound" }, "Bound Metadata"))),
                        metaDataTypes.some(function (metaData) { return metaData.type === MetaDataType.SHRINK_WRAP; }) && (React.createElement(Nav.Item, null,
                            React.createElement(Nav.Link, { eventKey: "shrink-wrap" }, "Shrink Wrap"))))),
                React.createElement(Col, { xs: 9, lg: 10 },
                    React.createElement(Tab.Content, null,
                        metaDataTypes.some(function (metaData) { return metaData.type === MetaDataType.COPIES; }) && (React.createElement(Tab.Pane, { eventKey: "copies" },
                            React.createElement(Tab.Container, { id: "copies-tabs", defaultActiveKey: "copies-editor" },
                                React.createElement("div", { className: "mb-4" },
                                    React.createElement(Nav, { variant: "tabs" },
                                        React.createElement(Nav.Item, null,
                                            React.createElement(Nav.Link, { eventKey: "copies-editor" }, "Copies")),
                                        React.createElement(Nav.Item, null,
                                            React.createElement(Nav.Link, { eventKey: "copies-changelog" }, "Change Logs")))),
                                React.createElement(Tab.Content, null,
                                    React.createElement(Tab.Pane, { eventKey: "copies-editor" },
                                        React.createElement(CopiesMetadataEditor, { productId: props.productId, subProductId: props.subProductId, santaType: props.santaType })),
                                    React.createElement(Tab.Pane, { eventKey: "copies-changelog" },
                                        React.createElement("div", { className: "p-3" },
                                            React.createElement(ApiChangeLogs, { shopId: props.shopId, logType: (_a = logTypeOptions.find(function (option) { return option.value === 'PRODUCT_METADATA_COPIES'; })) === null || _a === void 0 ? void 0 : _a.value, logObjectId: "".concat(props.productId).concat(props.subProductId).concat(props.santaType) }))))))),
                        metaDataTypes.some(function (metaData) { return metaData.type === MetaDataType.CUSTOM_SIZE; }) && (React.createElement(Tab.Pane, { eventKey: "custom-size" },
                            React.createElement(Tab.Container, { id: "custom-size-tabs", defaultActiveKey: "custom-size-editor" },
                                React.createElement("div", { className: "mb-4" },
                                    React.createElement(Nav, { variant: "tabs" },
                                        React.createElement(Nav.Item, null,
                                            React.createElement(Nav.Link, { eventKey: "custom-size-editor" }, "Custom Size")),
                                        React.createElement(Nav.Item, null,
                                            React.createElement(Nav.Link, { eventKey: "custom-size-changelog" }, "Change Logs")))),
                                React.createElement(Tab.Content, null,
                                    React.createElement(Tab.Pane, { eventKey: "custom-size-editor" },
                                        React.createElement(CustomSizeMetaDataEditor, { productId: props.productId, subProductId: props.subProductId, santaType: props.santaType })),
                                    React.createElement(Tab.Pane, { eventKey: "custom-size-changelog" },
                                        React.createElement("div", { className: "p-3" },
                                            React.createElement(ApiChangeLogs, { shopId: props.shopId, logType: (_b = logTypeOptions.find(function (option) { return option.value === 'PRODUCT_METADATA_CUSTOM_SIZES'; })) === null || _b === void 0 ? void 0 : _b.value, logObjectId: "".concat(props.productId).concat(props.subProductId).concat(props.santaType) }))))))),
                        metaDataTypes.some(function (metaData) { return metaData.type === MetaDataType.ORIENTATION; }) && (catalogue === null || catalogue === void 0 ? void 0 : catalogue.orientations) && (React.createElement(Tab.Pane, { eventKey: "orientation" },
                            React.createElement(Tab.Container, { id: "orientation-tabs", defaultActiveKey: "orientation-editor" },
                                React.createElement("div", { className: "mb-4" },
                                    React.createElement(Nav, { variant: "tabs" },
                                        React.createElement(Nav.Item, null,
                                            React.createElement(Nav.Link, { eventKey: "orientation-editor" }, "Orientation")),
                                        React.createElement(Nav.Item, null,
                                            React.createElement(Nav.Link, { eventKey: "orientation-changelog" }, "Change Logs")))),
                                React.createElement(Tab.Content, null,
                                    React.createElement(Tab.Pane, { eventKey: "orientation-editor" },
                                        React.createElement(OrientationMetaDataEditor, { productId: props.productId, subProductId: props.subProductId, santaType: props.santaType, orientations: catalogue.orientations })),
                                    React.createElement(Tab.Pane, { eventKey: "orientation-changelog" },
                                        React.createElement("div", { className: "p-3" },
                                            React.createElement(ApiChangeLogs, { shopId: props.shopId, logType: (_c = logTypeOptions.find(function (option) { return option.value === 'PRODUCT_METADATA_ORIENTATION'; })) === null || _c === void 0 ? void 0 : _c.value, logObjectId: "".concat(props.productId).concat(props.subProductId).concat(props.santaType) }))))))),
                        metaDataTypes.some(function (metaData) { return metaData.type === MetaDataType.COLOURS; }) && (function () {
                            var _a;
                            var coloursOptions = metaDataTypes.find(function (metaData) { return metaData.type === MetaDataType.COLOURS; });
                            return (React.createElement(Tab.Pane, { eventKey: "colours" },
                                React.createElement(Tab.Container, { id: "colours-tabs", defaultActiveKey: "colours-editor" },
                                    React.createElement("div", { className: "mb-4" },
                                        React.createElement(Nav, { variant: "tabs" },
                                            React.createElement(Nav.Item, null,
                                                React.createElement(Nav.Link, { eventKey: "colours-editor" }, "Colours")),
                                            React.createElement(Nav.Item, null,
                                                React.createElement(Nav.Link, { eventKey: "colours-changelog" }, "Change Logs")))),
                                    React.createElement(Tab.Content, null,
                                        React.createElement(Tab.Pane, { eventKey: "colours-editor" },
                                            React.createElement(ColoursMetaDataEditor, { productId: props.productId, subProductId: props.subProductId, santaType: props.santaType, coloursOptionsAvailable: (coloursOptions === null || coloursOptions === void 0 ? void 0 : coloursOptions.coloursOptionsAvailable) || false, backColoursOptionsAvailable: (coloursOptions === null || coloursOptions === void 0 ? void 0 : coloursOptions.backColoursOptionsAvailable) || false, innerCoverColoursOptionsAvailable: (coloursOptions === null || coloursOptions === void 0 ? void 0 : coloursOptions.innerCoverColoursOptionsAvailable) || false, outerCoverColoursOptionsAvailable: (coloursOptions === null || coloursOptions === void 0 ? void 0 : coloursOptions.outerCoverColoursOptionsAvailable) || false, jacketColoursOptionsAvailable: (coloursOptions === null || coloursOptions === void 0 ? void 0 : coloursOptions.jacketColoursOptionsAvailable) || false })),
                                        React.createElement(Tab.Pane, { eventKey: "colours-changelog" },
                                            React.createElement("div", { className: "p-3" },
                                                React.createElement(ApiChangeLogs, { shopId: props.shopId, logType: (_a = logTypeOptions.find(function (option) { return option.value === 'PRODUCT_METADATA_COLOURS'; })) === null || _a === void 0 ? void 0 : _a.value, logObjectId: "".concat(props.productId).concat(props.subProductId).concat(props.santaType) })))))));
                        })(),
                        metaDataTypes.some(function (metaData) { return metaData.type === MetaDataType.STANDARD_SIZES; }) && (function () {
                            var _a;
                            var standardSizeOptions = metaDataTypes.find(function (metaData) { return metaData.type === MetaDataType.STANDARD_SIZES; });
                            return (React.createElement(Tab.Pane, { eventKey: "standardSizes" },
                                React.createElement(Tab.Container, { id: "standard-sizes-tabs", defaultActiveKey: "standard-sizes-editor" },
                                    React.createElement("div", { className: "mb-4" },
                                        React.createElement(Nav, { variant: "tabs" },
                                            React.createElement(Nav.Item, null,
                                                React.createElement(Nav.Link, { eventKey: "standard-sizes-editor" }, "Standard Sizes")),
                                            React.createElement(Nav.Item, null,
                                                React.createElement(Nav.Link, { eventKey: "standard-sizes-changelog" }, "Change Logs")))),
                                    React.createElement(Tab.Content, null,
                                        React.createElement(Tab.Pane, { eventKey: "standard-sizes-editor" },
                                            React.createElement(StandardSizesMetaDataEditor, { productId: props.productId, subProductId: props.subProductId, santaType: props.santaType, dinSizes: catalogue.dinSizes, standardSizes: catalogue.standardSizes, simpleFolds: catalogue.simpleFolds, foldingAvailable: (standardSizeOptions === null || standardSizeOptions === void 0 ? void 0 : standardSizeOptions.foldingAvailable) || false })),
                                        React.createElement(Tab.Pane, { eventKey: "standard-sizes-changelog" },
                                            React.createElement("div", { className: "p-3" },
                                                React.createElement(ApiChangeLogs, { shopId: props.shopId, logType: (_a = logTypeOptions.find(function (option) { return option.value === 'PRODUCT_METADATA_STANDARD_SIZES'; })) === null || _a === void 0 ? void 0 : _a.value, logObjectId: "".concat(props.productId).concat(props.subProductId).concat(props.santaType) })))))));
                        })(),
                        metaDataTypes.some(function (metaData) { return metaData.type === MetaDataType.SUBSTRATE_TYPES; }) && (function () {
                            var _a;
                            var substrateTypesOptions = metaDataTypes.find(function (metaData) { return metaData.type === MetaDataType.SUBSTRATE_TYPES; });
                            return (React.createElement(Tab.Pane, { eventKey: "substrate-types" },
                                React.createElement(Tab.Container, { id: "substrate-types-tabs", defaultActiveKey: "substrate-types-editor" },
                                    React.createElement("div", { className: "mb-4" },
                                        React.createElement(Nav, { variant: "tabs" },
                                            React.createElement(Nav.Item, null,
                                                React.createElement(Nav.Link, { eventKey: "substrate-types-editor" }, "Substrates")),
                                            React.createElement(Nav.Item, null,
                                                React.createElement(Nav.Link, { eventKey: "substrate-types-changelog" }, "Change Logs")))),
                                    React.createElement(Tab.Content, null,
                                        React.createElement(Tab.Pane, { eventKey: "substrate-types-editor" },
                                            React.createElement(SubstrateTypesMetadataEditor, { productId: props.productId, subProductId: props.subProductId, santaType: props.santaType, substrateTypes: catalogue.substrateTypes, substratesAvailable: (substrateTypesOptions === null || substrateTypesOptions === void 0 ? void 0 : substrateTypesOptions.substrates) || false, coverSubstratesAvailable: (substrateTypesOptions === null || substrateTypesOptions === void 0 ? void 0 : substrateTypesOptions.coverSubstrates) || false, envelopeSubstratesAvailable: (substrateTypesOptions === null || substrateTypesOptions === void 0 ? void 0 : substrateTypesOptions.envelopeSubstrates) || false })),
                                        React.createElement(Tab.Pane, { eventKey: "substrate-types-changelog" },
                                            React.createElement("div", { className: "p-3" },
                                                React.createElement(ApiChangeLogs, { shopId: props.shopId, logType: (_a = logTypeOptions.find(function (option) { return option.value === 'PRODUCT_METADATA_SUBSTRATE_TYPES'; })) === null || _a === void 0 ? void 0 : _a.value, logObjectId: "".concat(props.productId).concat(props.subProductId).concat(props.santaType) })))))));
                        })(),
                        metaDataTypes.some(function (metaData) { return metaData.type === MetaDataType.LAMINATIONS; }) && (function () {
                            var _a;
                            var laminationOptions = metaDataTypes.find(function (metaData) { return metaData.type === MetaDataType.LAMINATIONS; });
                            return (React.createElement(Tab.Pane, { eventKey: "lamination" },
                                React.createElement(Tab.Container, { id: "lamination-tabs", defaultActiveKey: "lamination-editor" },
                                    React.createElement("div", { className: "mb-4" },
                                        React.createElement(Nav, { variant: "tabs" },
                                            React.createElement(Nav.Item, null,
                                                React.createElement(Nav.Link, { eventKey: "lamination-editor" }, "Lamination")),
                                            React.createElement(Nav.Item, null,
                                                React.createElement(Nav.Link, { eventKey: "lamination-changelog" }, "Change Logs")))),
                                    React.createElement(Tab.Content, null,
                                        React.createElement(Tab.Pane, { eventKey: "lamination-editor" },
                                            React.createElement(LaminationsMetadataEditor, { productId: props.productId, subProductId: props.subProductId, santaType: props.santaType, laminations: catalogue.laminations, frontAvailable: (laminationOptions === null || laminationOptions === void 0 ? void 0 : laminationOptions.frontAvailable) || false, backAvailable: (laminationOptions === null || laminationOptions === void 0 ? void 0 : laminationOptions.backAvailable) || false, coverAvailable: (laminationOptions === null || laminationOptions === void 0 ? void 0 : laminationOptions.coverAvailable) || false, dustJacketAvailable: (laminationOptions === null || laminationOptions === void 0 ? void 0 : laminationOptions.dustJacketAvailable) || false })),
                                        React.createElement(Tab.Pane, { eventKey: "lamination-changelog" },
                                            React.createElement("div", { className: "p-3" },
                                                React.createElement(ApiChangeLogs, { shopId: props.shopId, logType: (_a = logTypeOptions.find(function (option) { return option.value === 'PRODUCT_METADATA_LAMINATION'; })) === null || _a === void 0 ? void 0 : _a.value, logObjectId: "".concat(props.productId).concat(props.subProductId).concat(props.santaType) })))))));
                        })(),
                        metaDataTypes.some(function (metaData) { return metaData.type === MetaDataType.FOILING; }) && (function () {
                            var _a;
                            var foilingOptions = metaDataTypes.find(function (metaData) { return metaData.type === MetaDataType.FOILING; });
                            return (React.createElement(Tab.Pane, { eventKey: "foiling" },
                                React.createElement(Tab.Container, { id: "foiling-tabs", defaultActiveKey: "foiling-editor" },
                                    React.createElement("div", { className: "mb-4" },
                                        React.createElement(Nav, { variant: "tabs" },
                                            React.createElement(Nav.Item, null,
                                                React.createElement(Nav.Link, { eventKey: "foiling-editor" }, "Foiling")),
                                            React.createElement(Nav.Item, null,
                                                React.createElement(Nav.Link, { eventKey: "foiling-changelog" }, "Change Logs")))),
                                    React.createElement(Tab.Content, null,
                                        React.createElement(Tab.Pane, { eventKey: "foiling-editor" },
                                            React.createElement(FoilingMetaDataEditor, { productId: props.productId, subProductId: props.subProductId, santaType: props.santaType, frontAvailable: (foilingOptions === null || foilingOptions === void 0 ? void 0 : foilingOptions.frontAvailable) || false, backAvailable: (foilingOptions === null || foilingOptions === void 0 ? void 0 : foilingOptions.backAvailable) || false, coverAvailable: (foilingOptions === null || foilingOptions === void 0 ? void 0 : foilingOptions.coverAvailable) || false, dustJacketAvailable: (foilingOptions === null || foilingOptions === void 0 ? void 0 : foilingOptions.dustJacketAvailable) || false })),
                                        React.createElement(Tab.Pane, { eventKey: "foiling-changelog" },
                                            React.createElement("div", { className: "p-3" },
                                                React.createElement(ApiChangeLogs, { shopId: props.shopId, logType: (_a = logTypeOptions.find(function (option) { return option.value === 'PRODUCT_METADATA_FOILING'; })) === null || _a === void 0 ? void 0 : _a.value, logObjectId: "".concat(props.productId).concat(props.subProductId).concat(props.santaType) })))))));
                        })(),
                        metaDataTypes.some(function (metaData) { return metaData.type === MetaDataType.CUSTOMS_EXPORT_DATA; }) && (React.createElement(Tab.Pane, { eventKey: "customs-export-data" },
                            React.createElement(Tab.Container, { id: "customs-export-tabs", defaultActiveKey: "customs-export-editor" },
                                React.createElement("div", { className: "mb-4" },
                                    React.createElement(Nav, { variant: "tabs" },
                                        React.createElement(Nav.Item, null,
                                            React.createElement(Nav.Link, { eventKey: "customs-export-editor" }, "Customs Export Data")),
                                        React.createElement(Nav.Item, null,
                                            React.createElement(Nav.Link, { eventKey: "customs-export-changelog" }, "Change Logs")))),
                                React.createElement(Tab.Content, null,
                                    React.createElement(Tab.Pane, { eventKey: "customs-export-editor" },
                                        React.createElement(CustomsExportMetaDataEditor, { productId: props.productId, subProductId: props.subProductId, santaType: props.santaType })),
                                    React.createElement(Tab.Pane, { eventKey: "customs-export-changelog" },
                                        React.createElement("div", { className: "p-3" },
                                            React.createElement(ApiChangeLogs, { shopId: props.shopId, logType: (_d = logTypeOptions.find(function (option) { return option.value === 'PRODUCT_METADATA_CUSTOMS_EXPORT'; })) === null || _d === void 0 ? void 0 : _d.value, logObjectId: "".concat(props.productId).concat(props.subProductId).concat(props.santaType) }))))))),
                        metaDataTypes.some(function (metaData) { return metaData.type === MetaDataType.PRE_DRILLED_HOLES; }) && (React.createElement(Tab.Pane, { eventKey: "pre-drilled-holes" },
                            React.createElement(Tab.Container, { id: "pre-drilled-holes-tabs", defaultActiveKey: "pre-drilled-holes-editor" },
                                React.createElement("div", { className: "mb-4" },
                                    React.createElement(Nav, { variant: "tabs" },
                                        React.createElement(Nav.Item, null,
                                            React.createElement(Nav.Link, { eventKey: "pre-drilled-holes-editor" }, "Pre Drilled Holes")),
                                        React.createElement(Nav.Item, null,
                                            React.createElement(Nav.Link, { eventKey: "pre-drilled-holes-changelog" }, "Change Logs")))),
                                React.createElement(Tab.Content, null,
                                    React.createElement(Tab.Pane, { eventKey: "pre-drilled-holes-editor" },
                                        React.createElement(PreDrilledHolesMetaDataEditor, { productId: props.productId, subProductId: props.subProductId, santaType: props.santaType })),
                                    React.createElement(Tab.Pane, { eventKey: "pre-drilled-holes-changelog" },
                                        React.createElement("div", { className: "p-3" },
                                            React.createElement(ApiChangeLogs, { shopId: props.shopId, logType: (_e = logTypeOptions.find(function (option) { return option.value === 'PRODUCT_METADATA_PRE_DRILLED_HOLES'; })) === null || _e === void 0 ? void 0 : _e.value, logObjectId: "".concat(props.productId).concat(props.subProductId).concat(props.santaType) }))))))),
                        metaDataTypes.some(function (metaData) { return metaData.type === MetaDataType.BOUND; }) && (React.createElement(Tab.Pane, { eventKey: "bound" },
                            React.createElement(Tab.Container, { id: "bound-tabs", defaultActiveKey: "bound-editor" },
                                React.createElement("div", { className: "mb-4" },
                                    React.createElement(Nav, { variant: "tabs" },
                                        React.createElement(Nav.Item, null,
                                            React.createElement(Nav.Link, { eventKey: "bound-editor" }, "Bound Metadata")),
                                        React.createElement(Nav.Item, null,
                                            React.createElement(Nav.Link, { eventKey: "bound-changelog" }, "Change Logs")))),
                                React.createElement(Tab.Content, null,
                                    React.createElement(Tab.Pane, { eventKey: "bound-editor" },
                                        React.createElement(BoundMetaDataEditor, { productId: props.productId, subProductId: props.subProductId, santaType: props.santaType, bindingColours: catalogue.bindingColours, ribbonColours: catalogue.ribbonColours, endPaperColours: catalogue.endPaperColours, headAndTailBands: catalogue.headAndTailBands, substrateTypes: catalogue.substrateTypes })),
                                    React.createElement(Tab.Pane, { eventKey: "bound-changelog" },
                                        React.createElement("div", { className: "p-3" },
                                            React.createElement(ApiChangeLogs, { shopId: props.shopId, logType: (_f = logTypeOptions.find(function (option) { return option.value === 'PRODUCT_METADATA_BOUND'; })) === null || _f === void 0 ? void 0 : _f.value, logObjectId: "".concat(props.productId).concat(props.subProductId).concat(props.santaType) }))))))),
                        metaDataTypes.some(function (metaData) { return metaData.type === MetaDataType.SHRINK_WRAP; }) && (React.createElement(Tab.Pane, { eventKey: "shrink-wrap" },
                            React.createElement(Tab.Container, { id: "shrink-wrap-tabs", defaultActiveKey: "shrink-wrap-editor" },
                                React.createElement("div", { className: "mb-4" },
                                    React.createElement(Nav, { variant: "tabs" },
                                        React.createElement(Nav.Item, null,
                                            React.createElement(Nav.Link, { eventKey: "shrink-wrap-editor" }, "Shrink Wrap")),
                                        React.createElement(Nav.Item, null,
                                            React.createElement(Nav.Link, { eventKey: "shrink-wrap-changelog" }, "Change Logs")))),
                                React.createElement(Tab.Content, null,
                                    React.createElement(Tab.Pane, { eventKey: "shrink-wrap-editor" },
                                        React.createElement(ShrinkWrapMetaDataEditor, { productId: props.productId, subProductId: props.subProductId, santaType: props.santaType, substrateTypes: catalogue.substrateTypes })),
                                    React.createElement(Tab.Pane, { eventKey: "shrink-wrap-changelog" },
                                        React.createElement("div", { className: "p-3" },
                                            React.createElement(ApiChangeLogs, { secureDomain: props.secureDomain, shopId: props.shopId, logType: 'PRODUCT_METADATA_SHRINK_WRAP', logType: (_g = logTypeOptions.find(function (option) { return option.value === 'PRODUCT_METADATA_SHRINK_WRAP'; })) === null || _g === void 0 ? void 0 : _g.value, logObjectId: "".concat(props.productId).concat(props.subProductId).concat(props.santaType) }))))))))))))));
};
export default ProductMetaDataEditor;
