var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useState } from 'react';
import { Button as BsButton } from 'react-bootstrap';
import { SheetsAndSizes } from './components/SheetsAndSizes';
import { ItemSizes } from './components/ItemSizes';
import { AvailableEquipment } from "./components/AvailableEquipment";
import axios from 'axios';
import styled from 'styled-components';
import { Lamination } from "./components/Lamination";
import { ComponentSetupSheets } from "./components/ComponentSetupSheets";
import { EditMaterials } from "./components/EditMaterials";
import { QueryClient } from 'react-query';
import ChangeLogTabComponent from "../../../../../assets/react/change-logs/ChangeLogTabComponent";
import { HeadAndTailBands } from "./components/HeadAndTailBands";
import { RibbonColour } from "./components/RibbonColour";
import { ChangeLogType } from "@mixam-platform/mixam-types";
var Button = styled(BsButton)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    &.btn-outline-primary{\n        &:active, &.active, &:hover {\n            color: white !important;\n        }\n    }\n"], ["\n    &.btn-outline-primary{\n        &:active, &.active, &:hover {\n            color: white !important;\n        }\n    }\n"])));
export var getConfiguration = function (machineName) { return __awaiter(void 0, void 0, void 0, function () {
    var res, e_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, axios.get("/api/admin/tco/configurations/".concat(machineName))];
            case 1:
                res = _a.sent();
                return [2 /*return*/, res.data];
            case 2:
                e_1 = _a.sent();
                console.error('error');
                throw e_1;
            case 3: return [2 /*return*/];
        }
    });
}); };
export var getAwesomeQueryBuilder = function () { return __awaiter(void 0, void 0, void 0, function () {
    var res, e_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, axios.get('/admin/api/awesome-query-builder/config')];
            case 1:
                res = _a.sent();
                return [2 /*return*/, res.data];
            case 2:
                e_2 = _a.sent();
                console.error('error');
                throw e_2;
            case 3: return [2 /*return*/];
        }
    });
}); };
function TcoConfigurationEditor(_a) {
    var machineName = _a.machineName;
    var _b = useState('sheets'), activeTab = _b[0], setActiveTab = _b[1];
    var _c = useState(), configuration = _c[0], setConfiguration = _c[1];
    var _d = useState(), awesomeQueryBuilder = _d[0], setAwesomeQueryBuilder = _d[1];
    var doGetConfiguration = function () { return getConfiguration(machineName).then(setConfiguration); };
    useEffect(function () {
        doGetConfiguration().then(function (configuration) { return console.log(configuration); } /* TODO: remove! */);
        getAwesomeQueryBuilder().then(setAwesomeQueryBuilder);
    }, []);
    console.log(configuration);
    var queryClient = new QueryClient();
    return (React.createElement("div", { className: "d-flex" },
        React.createElement("div", { className: "side-bar text-nowrap d-flex p-3 gap-2 flex-column text-decoration-none border-end" },
            React.createElement(Button, { variant: "outline-primary", active: activeTab === 'sheets', onClick: function () { return setActiveTab('sheets'); } }, "Sheets and Sizes"),
            React.createElement(Button, { variant: "outline-primary", active: activeTab === 'available-equipment', onClick: function () { return setActiveTab('available-equipment'); } }, "Available Equipment"),
            React.createElement(Button, { variant: "outline-primary", active: activeTab === 'materials', onClick: function () { return setActiveTab('materials'); } }, "Materials"),
            React.createElement(Button, { variant: "outline-primary", active: activeTab === 'setup-sheets', onClick: function () { return setActiveTab('setup-sheets'); } }, "Setup Sheets"),
            React.createElement(Button, { variant: "outline-primary", active: activeTab === 'lamination', onClick: function () { return setActiveTab('lamination'); } }, "Lamination"),
            React.createElement(Button, { variant: "outline-primary", active: activeTab === 'headAndTailBands', onClick: function () { return setActiveTab('headAndTailBands'); } }, "Head & Tail Bands"),
            React.createElement(Button, { variant: "outline-primary", active: activeTab === 'ribbonColours', onClick: function () { return setActiveTab('ribbonColours'); } }, "Ribbon Colours")),
        React.createElement("div", { className: "p-4 flex-grow-1" },
            activeTab === 'sheets' && (React.createElement("div", { className: "d-flex flex-column gap-4" }, configuration ?
                React.createElement(React.Fragment, null,
                    React.createElement(ChangeLogTabComponent, { mainEventKey: 'sheetsAndSizesTab',
                        tabs: [
                            { key: 'sheets', label: 'Sheets' },
                            { key: 'sizes', label: 'Impositions' }
                        ],
                        components: [
                            {
                                component: SheetsAndSizes,
                                props: {
                                    configuration: configuration,
                                    doGetConfiguration: doGetConfiguration
                                }
                            },
                            {
                                component: ItemSizes,
                                props: {
                                    configuration: configuration,
                                    doGetConfiguration: doGetConfiguration,
                                    awesomeQueryBuilder: awesomeQueryBuilder
                                }
                            }
                        ],
                        changeLogProps: {
                            shopId: configuration.shopId,
                            logType: ChangeLogType.MACHINE_TCO_SHEETS_AND_SIZES,
                            logObjectId: configuration.id
                        },
                        queryClient: queryClient }))
                : 'loading')),
            activeTab === 'available-equipment' && (React.createElement(React.Fragment, null, configuration && awesomeQueryBuilder ?
                React.createElement(ChangeLogTabComponent, { mainEventKey: 'availableEquipmentTab',
                    tabs: [
                        { key: 'availableEquipment', label: 'Available Equipment' }
                    ],
                    components: [
                        {
                            component: AvailableEquipment,
                            props: {
                                configuration: configuration,
                                doGetConfiguration: doGetConfiguration
                            }
                        },
                    ],
                    changeLogProps: {
                        shopId: configuration.shopId,
                        logType: ChangeLogType.MACHINE_TCO_AVAILABLE_EQUIPMENT,
                        logObjectId: configuration.id
                    },
                    queryClient: queryClient })
                : 'loading')),
            activeTab === 'materials' && (React.createElement(React.Fragment, null, configuration && awesomeQueryBuilder ?
                React.createElement(ChangeLogTabComponent, { mainEventKey: 'materialsTab',
                    tabs: [
                        { key: 'materials', label: 'Materials' }
                    ],
                    components: [
                        {
                            component: EditMaterials,
                            props: {
                                configuration: configuration,
                                awesomeQueryBuilder: awesomeQueryBuilder,
                                doGetConfiguration: doGetConfiguration
                            }
                        }
                    ],
                    changeLogProps: {
                        shopId: configuration.shopId,
                        logType: ChangeLogType.MACHINE_TCO_MATERIALS,
                        logObjectId: configuration.id
                    },
                    queryClient: queryClient })
                : 'loading')),
            activeTab === 'setup-sheets' && (React.createElement(React.Fragment, null, configuration && awesomeQueryBuilder ?
                React.createElement(ChangeLogTabComponent, { mainEventKey: 'setupSheetsTab',
                    tabs: [
                        { key: 'setupSheets', label: 'Setup Sheets' }
                    ],
                    components: [
                        {
                            component: ComponentSetupSheets,
                            props: {
                                configuration: configuration,
                                awesomeQueryBuilder: awesomeQueryBuilder,
                                doGetConfiguration: doGetConfiguration
                            }
                        }
                    ],
                    changeLogProps: {
                        shopId: configuration.shopId,
                        logType: ChangeLogType.MACHINE_TCO_SETUP_SHEETS,
                        logObjectId: configuration.id
                    },
                    queryClient: queryClient })
                : 'loading')),
            activeTab === 'lamination' && (React.createElement(React.Fragment, null, configuration && awesomeQueryBuilder ?
                React.createElement(ChangeLogTabComponent, { mainEventKey: 'laminationTab',
                    tabs: [
                        { key: 'lamination', label: 'Lamination' }
                    ],
                    components: [
                        {
                            component: Lamination,
                            props: {
                                configuration: configuration,
                                awesomeQueryBuilder: awesomeQueryBuilder,
                                doGetConfiguration: doGetConfiguration
                            }
                        }
                    ],
                    changeLogProps: {
                        shopId: configuration.shopId,
                        logType: ChangeLogType.MACHINE_TCO_LAMINATION,
                        logObjectId: configuration.id
                    },
                    queryClient: queryClient })
                : 'loading')),
            activeTab === 'headAndTailBands' && (React.createElement(React.Fragment, null, configuration && awesomeQueryBuilder ?
                React.createElement(ChangeLogTabComponent, { mainEventKey: 'headAndTailBandsTab',
                    tabs: [
                        { key: 'headAndTailBands', label: 'Head & Tail Bands' }
                    ],
                    components: [
                        {
                            component: HeadAndTailBands,
                            props: {
                                configuration: configuration,
                                awesomeQueryBuilder: awesomeQueryBuilder,
                                doGetConfiguration: doGetConfiguration
                            }
                        }
                    ],
                    changeLogProps: {
                        shopId: configuration.shopId,
                        logType: ChangeLogType.MACHINE_TCO_HEAD_AND_TAIL_BANDS,
                        logObjectId: configuration.id
                    },
                    queryClient: queryClient })
                : 'loading')),
            activeTab === 'ribbonColours' && (React.createElement(React.Fragment, null, configuration && awesomeQueryBuilder ?
                React.createElement(ChangeLogTabComponent, { mainEventKey: 'ribbonColoursTab',
                    tabs: [
                        { key: 'ribbonColours', label: 'Ribbon Colours' }
                    ],
                    components: [
                        {
                            component: RibbonColour,
                            props: {
                                configuration: configuration,
                                awesomeQueryBuilder: awesomeQueryBuilder,
                                doGetConfiguration: doGetConfiguration
                            }
                        }
                    ],
                    changeLogProps: {
                        shopId: configuration.shopId,
                        logType: ChangeLogType.MACHINE_TCO_RIBBON_COLOUR,
                        logObjectId: configuration.id
                    },
                    queryClient: queryClient })
                : 'loading')))));
}
export default TcoConfigurationEditor;
var templateObject_1;
