export var MetaDataType;
(function (MetaDataType) {
    MetaDataType["COPIES"] = "COPIES";
    MetaDataType["COLOURS"] = "COLOURS";
    MetaDataType["ORIENTATION"] = "ORIENTATION";
    MetaDataType["STANDARD_SIZES"] = "STANDARD_SIZES";
    MetaDataType["SUBSTRATE_TYPES"] = "SUBSTRATE_TYPES";
    MetaDataType["LAMINATIONS"] = "LAMINATIONS";
    MetaDataType["FOILING"] = "FOILING";
    MetaDataType["CUSTOMS_EXPORT_DATA"] = "CUSTOMS_EXPORT_DATA";
    MetaDataType["PRE_DRILLED_HOLES"] = "PRE_DRILLED_HOLES";
    MetaDataType["BOUND"] = "BOUND";
    MetaDataType["SHRINK_WRAP"] = "SHRINK_WRAP";
    MetaDataType["CUSTOM_SIZE"] = "CUSTOM_SIZE";
})(MetaDataType || (MetaDataType = {}));
var FLAT_METADATA_TYPE_MAP = [
    { type: MetaDataType.COPIES },
    { type: MetaDataType.ORIENTATION },
    { type: MetaDataType.COLOURS, coloursOptionsAvailable: true, backColoursOptionsAvailable: true, innerCoverColoursOptionsAvailable: false, outerCoverColoursOptionsAvailable: false, jacketColoursOptionsAvailable: false },
    { type: MetaDataType.STANDARD_SIZES, foldingAvailable: false },
    { type: MetaDataType.SUBSTRATE_TYPES, substrates: true, coverSubstrates: false, envelopeSubstrates: false },
    { type: MetaDataType.LAMINATIONS, frontAvailable: true, backAvailable: true, coverAvailable: false, dustJacketAvailable: false },
    { type: MetaDataType.FOILING, frontAvailable: true, backAvailable: true, coverAvailable: false, dustJacketAvailable: false },
    { type: MetaDataType.CUSTOMS_EXPORT_DATA },
    { type: MetaDataType.PRE_DRILLED_HOLES },
    { type: MetaDataType.SHRINK_WRAP },
    { type: MetaDataType.CUSTOM_SIZE },
];
var FOLDED_METADATA_TYPE_MAP = [
    { type: MetaDataType.COPIES },
    { type: MetaDataType.ORIENTATION },
    { type: MetaDataType.COLOURS, coloursOptionsAvailable: true, backColoursOptionsAvailable: true, innerCoverColoursOptionsAvailable: false, outerCoverColoursOptionsAvailable: false, jacketColoursOptionsAvailable: false },
    { type: MetaDataType.STANDARD_SIZES, foldingAvailable: true },
    { type: MetaDataType.SUBSTRATE_TYPES, substrates: true, coverSubstrates: false, envelopeSubstrates: false },
    { type: MetaDataType.LAMINATIONS, frontAvailable: true, backAvailable: true, coverAvailable: false, dustJacketAvailable: false },
    { type: MetaDataType.FOILING, frontAvailable: true, backAvailable: true, coverAvailable: false, dustJacketAvailable: false },
    { type: MetaDataType.CUSTOMS_EXPORT_DATA },
    { type: MetaDataType.PRE_DRILLED_HOLES },
    { type: MetaDataType.SHRINK_WRAP },
    { type: MetaDataType.CUSTOM_SIZE },
];
var BOUND_METADATA_TYPE_MAP = [
    { type: MetaDataType.COPIES },
    { type: MetaDataType.ORIENTATION },
    { type: MetaDataType.COLOURS, coloursOptionsAvailable: true, backColoursOptionsAvailable: false, innerCoverColoursOptionsAvailable: true, outerCoverColoursOptionsAvailable: true, jacketColoursOptionsAvailable: false },
    { type: MetaDataType.STANDARD_SIZES, foldingAvailable: false },
    { type: MetaDataType.SUBSTRATE_TYPES, substrates: true, coverSubstrates: true, envelopeSubstrates: false },
    { type: MetaDataType.LAMINATIONS, frontAvailable: false, backAvailable: false, coverAvailable: true, dustJacketAvailable: false },
    { type: MetaDataType.FOILING, frontAvailable: false, backAvailable: false, coverAvailable: true, dustJacketAvailable: false },
    { type: MetaDataType.CUSTOMS_EXPORT_DATA },
    { type: MetaDataType.PRE_DRILLED_HOLES },
    { type: MetaDataType.BOUND },
    { type: MetaDataType.SHRINK_WRAP },
    { type: MetaDataType.CUSTOM_SIZE },
];
var LAYFLAT_METADATA_TYPE_MAP = [
    { type: MetaDataType.COPIES },
    { type: MetaDataType.ORIENTATION },
    { type: MetaDataType.COLOURS, coloursOptionsAvailable: true, backColoursOptionsAvailable: false, innerCoverColoursOptionsAvailable: true, outerCoverColoursOptionsAvailable: true, jacketColoursOptionsAvailable: false },
    { type: MetaDataType.STANDARD_SIZES, foldingAvailable: false },
    { type: MetaDataType.SUBSTRATE_TYPES, substrates: true, coverSubstrates: true, envelopeSubstrates: false },
    { type: MetaDataType.LAMINATIONS, frontAvailable: true, backAvailable: false, coverAvailable: true, dustJacketAvailable: false },
    { type: MetaDataType.CUSTOMS_EXPORT_DATA },
    { type: MetaDataType.BOUND },
    { type: MetaDataType.SHRINK_WRAP },
    { type: MetaDataType.CUSTOM_SIZE },
];
var CASEBOUND_BOOK_METADATA_TYPE_MAP = [
    { type: MetaDataType.COPIES },
    { type: MetaDataType.ORIENTATION },
    { type: MetaDataType.COLOURS, coloursOptionsAvailable: true, backColoursOptionsAvailable: false, innerCoverColoursOptionsAvailable: true, outerCoverColoursOptionsAvailable: true, jacketColoursOptionsAvailable: true },
    { type: MetaDataType.STANDARD_SIZES, foldingAvailable: false },
    { type: MetaDataType.SUBSTRATE_TYPES, substrates: true, coverSubstrates: true, envelopeSubstrates: false },
    { type: MetaDataType.LAMINATIONS, frontAvailable: false, backAvailable: false, coverAvailable: true, dustJacketAvailable: true },
    { type: MetaDataType.FOILING, frontAvailable: false, backAvailable: false, coverAvailable: true, dustJacketAvailable: true },
    { type: MetaDataType.CUSTOMS_EXPORT_DATA },
    { type: MetaDataType.BOUND },
    { type: MetaDataType.SHRINK_WRAP },
    { type: MetaDataType.CUSTOM_SIZE },
];
var GREETING_CARDS_METADATA_TYPE_MAP = [
    { type: MetaDataType.COPIES },
    { type: MetaDataType.ORIENTATION },
    { type: MetaDataType.COLOURS, coloursOptionsAvailable: true, backColoursOptionsAvailable: true, innerCoverColoursOptionsAvailable: false, outerCoverColoursOptionsAvailable: false, jacketColoursOptionsAvailable: false },
    { type: MetaDataType.STANDARD_SIZES, foldingAvailable: true },
    { type: MetaDataType.SUBSTRATE_TYPES, substrates: true, coverSubstrates: false, envelopeSubstrates: true },
    { type: MetaDataType.LAMINATIONS, frontAvailable: true, backAvailable: true, coverAvailable: false, dustJacketAvailable: false },
    { type: MetaDataType.FOILING, frontAvailable: true, backAvailable: true, coverAvailable: false, dustJacketAvailable: false },
    { type: MetaDataType.CUSTOMS_EXPORT_DATA },
    { type: MetaDataType.SHRINK_WRAP },
    { type: MetaDataType.CUSTOM_SIZE },
];
var DUSTJACKET_METADATA_TYPE_MAP = [
    { type: MetaDataType.COPIES },
    { type: MetaDataType.ORIENTATION },
    { type: MetaDataType.COLOURS, coloursOptionsAvailable: true, backColoursOptionsAvailable: true, innerCoverColoursOptionsAvailable: false, outerCoverColoursOptionsAvailable: false, jacketColoursOptionsAvailable: false },
    { type: MetaDataType.STANDARD_SIZES, foldingAvailable: false },
    { type: MetaDataType.SUBSTRATE_TYPES, substrates: true, coverSubstrates: false, envelopeSubstrates: false },
    { type: MetaDataType.LAMINATIONS, frontAvailable: true, backAvailable: false, coverAvailable: false, dustJacketAvailable: false },
    { type: MetaDataType.FOILING, frontAvailable: false, backAvailable: false, coverAvailable: false, dustJacketAvailable: true },
    { type: MetaDataType.CUSTOMS_EXPORT_DATA },
    { type: MetaDataType.SHRINK_WRAP },
    { type: MetaDataType.CUSTOM_SIZE },
];
export var ProductMetaDataTypeMap = {
    BROCHURES: BOUND_METADATA_TYPE_MAP,
    FLYERS: FLAT_METADATA_TYPE_MAP,
    BOOK: CASEBOUND_BOOK_METADATA_TYPE_MAP,
    BUSINESS_CARDS: FOLDED_METADATA_TYPE_MAP,
    CANVAS: FLAT_METADATA_TYPE_MAP,
    COMPLIMENT_SLIPS: FLAT_METADATA_TYPE_MAP,
    DESK_CALENDARS: BOUND_METADATA_TYPE_MAP,
    FOLDED_LEAFLETS: FOLDED_METADATA_TYPE_MAP,
    GREETING_CARDS: GREETING_CARDS_METADATA_TYPE_MAP,
    LAYFLAT_BOOKS: LAYFLAT_METADATA_TYPE_MAP,
    LETTERHEADS: FLAT_METADATA_TYPE_MAP,
    NOTE_BOOKS: BOUND_METADATA_TYPE_MAP,
    POSTCARDS: FLAT_METADATA_TYPE_MAP,
    POSTERS: FLAT_METADATA_TYPE_MAP,
    VR_DESK_CALENDARS: BOUND_METADATA_TYPE_MAP,
    VR_WALL_CALENDARS: BOUND_METADATA_TYPE_MAP,
    WALL_CALENDARS: BOUND_METADATA_TYPE_MAP,
    DUST_JACKET: DUSTJACKET_METADATA_TYPE_MAP,
    // We Don't Actually Use These Products
    ENVELOPES: [],
    FOLDERS: [],
    FA_EXTREMELY_ADHESIVE_STICKERS: [],
    FA_FOOD_STICKERS: [],
    FA_GEL_STICKERS: [],
    FA_HEAT_RESISTANT_STICKERS: [],
    FA_INDOOR_STICKERS: [],
    FA_LUMINOUS_STICKERS: [],
    FA_NEON_STICKERS: [],
    FA_OUTDOOR_STICKERS: [],
    FA_REFLECTIVE_STICKERS: [],
    FA_REMOVABLE_STICKERS: [],
    FA_STICKER_ROLL: [],
    FA_STICKER_SHEET: [],
    FA_VEGAN_INDOOR_STICKERS: [],
    FA_VEGAN_OUTDOOR_STICKERS: [],
    PHOTO_BOOKS: [],
    TRADITIONAL_BOOKS: [],
};
export var UnitType;
(function (UnitType) {
    UnitType["MILLIMETERS"] = "MILLIMETERS";
    UnitType["INCHES"] = "INCHES";
})(UnitType || (UnitType = {}));
export var WeightUnit;
(function (WeightUnit) {
    WeightUnit["GSM"] = "GSM";
    WeightUnit["LBS_TEXT"] = "LBS_TEXT";
    WeightUnit["LBS_COVER"] = "LBS_COVER";
    WeightUnit["MICRONS"] = "MICRON";
})(WeightUnit || (WeightUnit = {}));
export var ColourLabelType;
(function (ColourLabelType) {
    ColourLabelType["NONE"] = "NONE";
    ColourLabelType["GREYSCALE"] = "GREYSCALE";
    ColourLabelType["FULL_COLOUR"] = "FULL_COLOUR";
    ColourLabelType["INNER_COVER_PRINTING_YES"] = "INNER_COVER_PRINTING_YES";
    ColourLabelType["INNER_COVER_PRINTING_NO"] = "INNER_COVER_PRINTING_NO";
})(ColourLabelType || (ColourLabelType = {}));
export var BindingTypeLabel;
(function (BindingTypeLabel) {
    BindingTypeLabel["DEFAULT"] = "DEFAULT";
    BindingTypeLabel["HARD_COVER"] = "HARD_COVER";
    BindingTypeLabel["SOFT_COVER"] = "SOFT_COVER";
    BindingTypeLabel["SEWN"] = "SEWN";
})(BindingTypeLabel || (BindingTypeLabel = {}));
