import React, { useEffect, useState } from 'react';
import { Button, Modal, Spinner } from "react-bootstrap";
import { getPurchaseOrderReconciliationData } from "./api/PurchaseOrderReconciliationData";
import mixam from "../../../../../boot/mixam.js";
import { utc } from "moment";
import Table from "react-bootstrap/Table";
var ViewPurchaseOrderModal = function (_a) {
    var fulfilmentId = _a.fulfilmentId;
    var _b = useState(null), reconciliationData = _b[0], setReconciliationData = _b[1];
    var _c = useState(false), showModal = _c[0], setShowModal = _c[1];
    var _d = useState(true), loading = _d[0], setLoading = _d[1];
    var handleCloseModal = function (data) { return setShowModal(false); };
    var handleShowModal = function () { return setShowModal(true); };
    useEffect(function () {
        if (showModal) {
            setLoading(true);
            getPurchaseOrderReconciliationData(fulfilmentId)
                .then(function (reconciliationData) {
                setReconciliationData(reconciliationData);
                setLoading(false);
            })
                .catch(function () {
                setReconciliationData(null);
                setLoading(false);
            });
        }
    }, [showModal, fulfilmentId]);
    var reconciliationView = function () {
        return (React.createElement(React.Fragment, null,
            reconciliationData.manualOverride &&
                React.createElement("div", { className: "alert alert-warning", role: "alert" }, "This reconciliation data has been manually overridden"),
            React.createElement("div", { className: "row" },
                React.createElement("div", { className: "col" },
                    React.createElement("h4", { className: "mb-3" },
                        "Invoice: ",
                        reconciliationData.invoiceNumber)),
                reconciliationData.invoiceDate &&
                    React.createElement("div", { className: "col" },
                        React.createElement("h4", { className: "mb-3" },
                            "Date: ",
                            utc(reconciliationData.invoiceDate).tz(mixam.shop.timeZone).format('YYYY-MM-DD'))),
                reconciliationData.dueDate &&
                    React.createElement("div", { className: "col" },
                        React.createElement("h4", { className: "mb-3" },
                            "Due: ",
                            utc(reconciliationData.dueDate).tz(mixam.shop.timeZone).format('YYYY-MM-DD')))),
            reconciliationData.supplier &&
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col" },
                        React.createElement("label", { htmlFor: "businessUnitName", className: "form-label h6" }, "Supplier"),
                        React.createElement("p", { id: "businessUnitName" }, reconciliationData.supplier.name),
                        React.createElement("label", { htmlFor: "vatRegistrationNumber", className: "form-label h6" }, "VAT Registration Number"),
                        reconciliationData.supplier.vatRegistrationNumber &&
                            React.createElement("p", { id: "vatRegistrationNumber" }, reconciliationData.supplier.vatRegistrationNumber)),
                    React.createElement("div", { className: "col" },
                        React.createElement("label", { htmlFor: "address", className: "form-label h6" }, "Address"),
                        React.createElement("p", { id: "address" },
                            reconciliationData.supplier.addressLine1 && React.createElement(React.Fragment, null,
                                reconciliationData.supplier.addressLine1,
                                React.createElement("br", null)),
                            reconciliationData.supplier.addressLine2 && React.createElement(React.Fragment, null,
                                reconciliationData.supplier.addressLine2,
                                React.createElement("br", null)),
                            reconciliationData.supplier.addressLine3 && React.createElement(React.Fragment, null,
                                reconciliationData.supplier.addressLine3,
                                React.createElement("br", null)),
                            reconciliationData.supplier.addressLine4 && React.createElement(React.Fragment, null,
                                reconciliationData.supplier.addressLine4,
                                React.createElement("br", null)),
                            reconciliationData.supplier.addressLine5 && React.createElement(React.Fragment, null,
                                reconciliationData.supplier.addressLine5,
                                React.createElement("br", null)),
                            reconciliationData.supplier.postcode && React.createElement(React.Fragment, null,
                                reconciliationData.supplier.postcode,
                                React.createElement("br", null)),
                            reconciliationData.supplier.contactNumber && React.createElement(React.Fragment, null, reconciliationData.supplier.contactNumber)))),
            React.createElement("div", { className: "row" },
                React.createElement("div", { className: "col" },
                    React.createElement("label", { htmlFor: "totalAmount", className: "form-label h6" }, "Total Amount"),
                    React.createElement("p", { id: "totalAmount" }, reconciliationData.totalAmount),
                    React.createElement("label", { htmlFor: "productAmount", className: "form-label h6" }, "Prooduct Amount"),
                    React.createElement("p", { id: "productAmount" }, reconciliationData.productAmount),
                    React.createElement("label", { htmlFor: "extraAmount", className: "form-label h6" }, "Extra Amount"),
                    React.createElement("p", { id: "extraAmount" }, reconciliationData.extraAmount))),
            reconciliationData.invoiceLines &&
                React.createElement(React.Fragment, null,
                    React.createElement("h4", { className: "mb-3" }, "Invoice Lines"),
                    React.createElement(Table, null,
                        React.createElement("thead", null,
                            React.createElement("tr", null,
                                React.createElement("th", null, "Quantity"),
                                React.createElement("th", null, "Description"),
                                React.createElement("th", null, "Amount"))),
                        React.createElement("tbody", null, reconciliationData.invoiceLines.map(function (invoiceLine, index) {
                            return (React.createElement("tr", { key: index },
                                React.createElement("td", null, invoiceLine.quantity),
                                React.createElement("td", null, invoiceLine.description),
                                React.createElement("td", null, invoiceLine.amount)));
                        }))))));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Button, { className: "btn btn-primary btn-sm w-100", onClick: handleShowModal }, "Purchase Order"),
        showModal && (React.createElement(Modal, { show: showModal, onHide: function () {
                handleCloseModal(null);
            }, size: "lg", centered: true },
            React.createElement(Modal.Header, { closeButton: true },
                React.createElement(Modal.Title, null, "Reconciliation Data")),
            React.createElement(Modal.Body, null,
                loading ? (React.createElement("div", { className: "text-center" },
                    React.createElement(Spinner, { animation: "border", role: "status", variant: "primary" },
                        React.createElement("span", { className: "visually-hidden" }, "Loading...")),
                    React.createElement("p", { className: "mt-3" }, "Loading reconciliation data..."))) : (React.createElement(React.Fragment, null, reconciliationData ? (React.createElement(React.Fragment, null,
                    reconciliationData.fulfillmentDispute &&
                        React.createElement("div", { className: "alert alert-danger", role: "alert" }, "This PO is in dispute!"),
                    reconciliationView())) : (React.createElement("p", null, "Reconciliation data not found")))),
                React.createElement("a", { href: "/admin/api/fulfilment/".concat(fulfilmentId, "/purchase-order.pdf") }, "Xero Purchase Order PDF"))))));
};
export default ViewPurchaseOrderModal;
