var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { Nav, Tab } from 'react-bootstrap';
import ApiChangeLogs from "./ApiChangeLogs";
import { QueryClientProvider } from 'react-query';
import PropTypes from 'prop-types';
var ChangeLogTabComponent = function (_a) {
    var mainEventKey = _a.mainEventKey, tabs = _a.tabs, components = _a.components, changeLogProps = _a.changeLogProps, queryClient = _a.queryClient;
    console.log('Rendering ChangeLogTabComponent with:', {
        mainEventKey: mainEventKey,
        tabs: tabs,
        components: components,
        changeLogProps: changeLogProps
    });
    return (React.createElement(Tab.Container, { id: "".concat(mainEventKey, "-tabs"), defaultActiveKey: "".concat(mainEventKey, "-").concat(tabs[0].key) },
        React.createElement("div", { className: "mb-4" },
            React.createElement(Nav, { variant: "tabs" },
                tabs.map(function (tab, index) { return (React.createElement(Nav.Item, { key: index },
                    React.createElement(Nav.Link, { eventKey: "".concat(mainEventKey, "-").concat(tab.key) }, tab.label))); }),
                React.createElement(Nav.Item, null,
                    React.createElement(Nav.Link, { eventKey: "".concat(mainEventKey, "-changelog") }, "Change Logs")))),
        React.createElement(Tab.Content, null,
            components.map(function (componentConfig, index) {
                var Component = componentConfig.component;
                return (React.createElement(Tab.Pane, { key: index, eventKey: "".concat(mainEventKey, "-").concat(tabs[index].key) },
                    React.createElement("div", null,
                        React.createElement(Component, __assign({}, componentConfig.props)))));
            }),
            React.createElement(Tab.Pane, { eventKey: "".concat(mainEventKey, "-changelog") },
                React.createElement("div", null,
                    React.createElement(QueryClientProvider, { client: queryClient },
                        React.createElement(ApiChangeLogs, __assign({}, changeLogProps))))))));
};
ChangeLogTabComponent.propTypes = {
    mainEventKey: PropTypes.string.isRequired,
    tabs: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired
    })).isRequired,
    components: PropTypes.arrayOf(PropTypes.shape({
        component: PropTypes.elementType.isRequired,
        props: PropTypes.object
    })).isRequired,
    changeLogProps: PropTypes.shape({
        shopId: PropTypes.string.isRequired,
        logType: PropTypes.string.isRequired,
        logObjectId: PropTypes.string.isRequired
    }).isRequired,
    queryClient: PropTypes.object.isRequired
};
export default ChangeLogTabComponent;
