import mixam from '../boot/mixam';
import defineComponent from '../../components/flight/lib/component';
import axios from "axios";

import * as reactEvent from '../component_ui/constants/react-events';

export default defineComponent(QuoteFetch);

function QuoteFetch() {

    this.attributes({
        topic: `/topic/${mixam.queryResponseTopicId}`,
        offerUrl: '/api/offer'
    });

    this.fetch = function (event, data, retry = 0) {

        this.latestRequestId = `${Math.floor(Math.random() * 2147483648).toString(36)}${Math.floor(Math.random() * 2147483648).toString(36)}`;
        this.callId = data.id;
        const santa = data.query || data;

        const offerRequest = {
            subProductId: santa.subProductId,
            productName: santa.productName,
            postCode: santa.postCode,
            requestId: this.latestRequestId,
            itemSpecification: santa.itemSpecification
        };

        axios.post(this.attr.offerUrl, offerRequest)
            .then((response) => {
                const offerResponse = response.data;
                offerResponse.id = this.callId;
                this.trigger("dataQuoteReady", offerResponse);
            }).catch(error => {

        });
    };

    this.subscribe = function () {
        this.trigger("log", {message: [`Subscribe to stomp channel ${this.topic}`], title: "QuoteFetch.subscribe"});
        this.stomp = Stomp.client(mixam.stompServiceUrl);

        this.stomp.debug = (...args) => {
            if (args.join('').indexOf('Whoops! Lost connection to') !== -1) {
                setTimeout(() => this.subscribe(), 10);
            }
            this.trigger("log", {message: args, title: "QuoteFetch.subscribe"});
        };

        this.stomp.connect(mixam.stompWebUser, mixam.stompWebPass, () => {
            this.stomp.subscribe(this.attr.topic, (d) => {
                const response = JSON.parse(d.body);
                response.data.isUpdate = true;
                this.trigger("dataQuoteReadyReactPriceCalculator", response.data);
                if (response.data.requestId === this.latestRequestId) {
                    this.trigger("dataQuoteReady", response.data);
                }
            });

        }, (err) => this.trigger("log", err), '/');
    };

    this.after('initialize', function () {
        this.latestRequestId = null;
        this.on("uiNeedQuote", this.fetch);
        this.subscribe();
        this.on("dataQuoteReadyReactPriceCalculator", reactEvent.propagateReactEvent);
    });
}
