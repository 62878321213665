import mixam from '../../boot/mixam';
import defineComponent from '../../../components/flight/lib/component';
import Utils from  '../../../components/flight/lib/utils';
import FixedHeader  from  './fixed-header';
import Ripple from   '../ripple';
import WithThrottling from  '../with-throttling';
import WithQueryParams from  '../with-query-params';
import DataPager from './data-pager';
import dict from  './reference-dictionary';
import WithTableReactor from  './with-table-reactor';
import React from 'react';
import ReactDom from  'react-dom/client';
import FloatingToolbarButton from './floating-toolbar-button';
import State from '../state';
import moment from 'moment';

var reSanitize = /<.+?>/g,
    veryLargeChar = "\u{FFA00}", // jshint ignore:line
    dateZero = new Date(1970, 0, 1).getTime(),
    sorters = {
        "trustpilot-stars": {
            asc: (key) => (a, b) => a[key] - b[key],
            desc: (key) => (a, b) => b[key] - a[key]
        },
        "number": {
            asc: (key) => (a, b) => a[key] - b[key],
            desc: (key) => (a, b) => b[key] - a[key]
        },
        "boolean": {
            asc: (key) => (a, b) => a[key] === b[key] ? 0 : (a[key] ? -1 : 1),
            desc: (key) => (a, b) => a[key] === b[key] ? 0 : (b[key] ? -1 : 1)
        },
        "date": {
            asc: (key) => (a, b) => (a[key].value || dateZero) - (b[key].value || dateZero),
            desc: (key) => (a, b) => (b[key].value || dateZero) - (a[key].value || dateZero)
        },
        "dispatch": {
            asc: (key) => (a, b) => (a[key].value || dateZero) - (b[key].value || dateZero),
            desc: (key) => (a, b) => (b[key].value || dateZero) - (a[key].value || dateZero)
        },
        "time": {
            asc: (key) => (a, b) => (a[key].value || dateZero) - (b[key].value || dateZero),
            desc: (key) => (a, b) => (b[key].value || dateZero) - (a[key].value || dateZero)
        },
        "datetime": {
            asc: (key) => (a, b) => (a[key].value || dateZero) - (b[key].value || dateZero),
            desc: (key) => (a, b) => (b[key].value || dateZero) - (a[key].value || dateZero)
        },
        "timebox": {
            asc: (key) => (a, b) => (a[key] && a[key].value || dateZero) - (b[key] && b[key].value || dateZero),
            desc: (key) => (a, b) => (b[key] && b[key].value || dateZero) - (a[key] && a[key].value || dateZero)
        },
        "remarks": {
            asc: (key) => (a, b) => (a[key] && a[key].value || 0) - (b[key] && b[key].value || 0),
            desc: (key) => (a, b) => (b[key] && b[key].value || 0) - (a[key] && a[key].value || 0)
        },
        "text": {
            asc: (key) => (a, b) => (a[key] || veryLargeChar).toString().localeCompare((b[key] || veryLargeChar)),
            desc: (key)=> (a, b) => (b[key] || "").toString().localeCompare((a[key] || ""))
        },
        "user": {
            asc: (key) => (a, b) => (a[key].name || veryLargeChar).toString().localeCompare((b[key].name || veryLargeChar)),
            desc: (key)=> (a, b) => (b[key].name || "").toString().localeCompare((a[key].name || ""))
        },
        "text-blob": {
            asc: (key) => (a, b) => (a[key].text || veryLargeChar).localeCompare((b[key].text || veryLargeChar)),
            desc: (key) => (a, b) => (b[key].text || "").localeCompare((a[key].text || ""))
        },
        "html": {
            asc: (key) => (a, b) => (a[key].text || veryLargeChar).localeCompare((b[key].text || veryLargeChar)),
            desc: (key) => (a, b) => (b[key].text || "").localeCompare((a[key].text || ""))
        },
        "icon": {
            asc: (key) => (a, b) => (a[key].caption || a[key].icon || veryLargeChar).localeCompare((b[key].caption || b[key].icon || veryLargeChar)),
            desc: (key) => (a, b) => (b[key].caption || b[key].icon || "").localeCompare((a[key].caption || a[key].icon || ""))
        },
        link: {
            asc: (key)=> (a, b) => (a[key].caption || "").toString().localeCompare((b[key].caption || "")),
            desc: (key) => (a, b) => (b[key].caption || "").toString().localeCompare((a[key].caption || ""))
        },
        status:{
            asc: (key) => (a, b) => (a[key] || veryLargeChar).toString().localeCompare((b[key] || veryLargeChar)),
            desc: (key)=> (a, b) => (b[key] || "").toString().localeCompare((a[key] || ""))
        },
        creditcommand:{
            asc: (key) => (a, b) => (a[key] || veryLargeChar).toString().localeCompare((b[key] || veryLargeChar)),
            desc: (key)=> (a, b) => (b[key] || "").toString().localeCompare((a[key] || ""))
        },
        command: {
            asc: (key) => (a, b) => (a[key] || veryLargeChar).toString().localeCompare((b[key] || veryLargeChar)),
            desc: (key)=> (a, b) => (b[key] || "").toString().localeCompare((a[key] || ""))
        }
    },

    nobleTypes = ["thumbnail", "lightbox"];

function dateToDow (timestamp) {
    if (timestamp) {
        return moment(timestamp).format("ddd, MMM D");
    }
}

function getTextNodesIn(node, includeWhitespaceNodes) {
    var textNodes = [], nonWhitespaceMatcher = /\S/;

    function getTextNodes(node) {
        if (node.nodeType === 3) {
            if (includeWhitespaceNodes || nonWhitespaceMatcher.test(node.nodeValue)) {
                textNodes.push(node);
            }
        } else {
            for (var i = 0, len = node.childNodes.length; i < len; ++i) {
                getTextNodes(node.childNodes[i]);
            }
        }
    }

    getTextNodes(node);
    return textNodes;
}


function getStartOfDay(days) {
    var now = new Date();

    return new Date(now.getFullYear(), now.getMonth(), now.getDate() + (days || 0), 0, 0, 0, 0).getTime();
}


function timeToTimebox(time) {
    var now = +new Date(),
        dateDiff = Math.round((now - time) / 1000 / 60),
        timeTemplate = "h:MM TT";

    if (dateDiff < 240) {
        return `<div class='timebox'><span class=value><span class=hours>${Math.floor(dateDiff / 60)}</span><span class='seperator'>:</span><span class='minutes'>${mixam.padl(dateDiff % 60, "0", 2)}</span></span><span class=caption>hours ago</span></div>`;
    } else if (time > getStartOfDay()) {
        return `<div class='timebox'><span class=value><span class=hours>${Math.round(dateDiff / 60)}</span></span><span class=caption>hours ago</span></div>`;
    } else if (time > getStartOfDay(-1)) {
        return `<div class='timebox'><div class=date-part>Yesterday</div><div class=time-part>${(new Date(time)).format(timeTemplate)}</div></div>`;
    } else if (dateDiff < 60 * 24 * 21) {
        return `<div class='timebox'><span class=value><span class=days>${Math.ceil(dateDiff / 60 / 24)}</span></span><span class=caption>days ago</span></div>`;
    }
    return '';
}

function compileTextFilter(operator, value) {
    var func,
        valueUpCase = value.toUpperCase();

    switch (operator) {
        case "cn":
            func = (compareTo) => compareTo.toString().toUpperCase().indexOf(valueUpCase) !== -1;
            break;
        case "ncn":
            func = (compareTo) => compareTo.toString().toUpperCase().indexOf(valueUpCase) === -1;
            break;
        case "eq":
            func = (compareTo) => compareTo.toString().toUpperCase() === valueUpCase;
            break;
        case "neq":
            func = (compareTo)=> compareTo.toString().toUpperCase() !== valueUpCase;
            break;
        case "regexp":
            try {
                var re = new RegExp(value, "i");
                func = (compareTo) => re.test(compareTo);
            } catch (e) {
                func = (compareTo) => false;
            }
            break;
    }
    return func;
}


function compileNumberFilter(operator, value) {
    var func;

    value = +value;

    switch (operator) {
        case "eq":
            func = compareTo => +compareTo === value;
            break;
        case "neq":
            func = compareTo => +compareTo !== value;
            break;
        case "gt":
            func = compareTo => +compareTo > value;
            break;
        case "gte":
            func = compareTo => +compareTo >= value;
            break;
        case "lt":
            func = compareTo => +compareTo < value;
            break;
        case "lte":
            func = compareTo => +compareTo <= value;
            break;
    }
    return func;
}

function compileTimeFilter(operator, value) {
    var func;

    value = value;

    switch (operator) {
        case "eq":
            func = compareTo => compareTo === value;
            break;
        case "gt":
            func = compareTo => +compareTo > value;
            break;
        case "gte":
            func = compareTo => +compareTo >= value;
            break;
        case "lt":
            func = compareTo => +compareTo < value;
            break;
        case "lte":
            func = compareTo => +compareTo <= value;
            break;
    }
    return func;
}


function compileDateFilter(operator, value) {
    return compareTo => +compareTo >= value.start && +compareTo <= value.end;
}

function compileBooleanFilter(value) {
    return compareTo => Boolean(compareTo) === Boolean(value);
}

/***
 * Filter class api
 *
 * @constructor
 */
function Filter() {
    this._and = [];
    this._or = [];
}

Filter.prototype.and = function (filters) {
    this._and.concat(filters);
};

Filter.prototype.or = function (filters) {
    this._or.concat(filters);
};

export default defineComponent(DataTable, WithThrottling, WithQueryParams, WithTableReactor);

function DataTable() {

    this.attributes({
        containerSelector: "section.table",
        filterBoxSelector: "section.filter",
        pagerSelector: "section.pager",
        sortHeaderSelector: "th.sortable",
        imageLightboxSelector: '[data-type="lightbox"]',
        textBlobBoxSelector: '[data-type="text-blob"]',
        imageFieldSelector: '[data-col="image"]',
        buttonSelector: '[data-type="toolbar-button"]',
        rippleSelector: '.ripple',
        tableSelector: '.main-table'
    });

    this.render = function () {
        this.select('containerSelector').removeClass("loading");
        const root = ReactDom.createRoot(this.select('containerSelector')[0]);
        root.render(
            React.createElement(this.getTableReactor(), {
                    data: this.getRenderData()
                }
            )
        );
    };

    this.getRenderData = function () {
        var page = this.getPage(),
            schema = this.updateSchema(),
            pageTotals = this.calcPageTotals(page, schema);

        if (pageTotals && pageTotals.totals) {
            pageTotals.totals.caption = "Page Total";
            if (this.needAverages) {
                pageTotals.averages.caption = "Page Average";
            }
        }

        if (this.filteredTotals && this.filteredTotals.totals) {
            this.filteredTotals.totals.caption = "Filtered Total";
            if (this.needAverages) {
                this.filteredTotals.averages.caption = "Filtered Average";
            }
        }

        if (this.totals && this.totals.totals) {
            this.totals.totals.caption = "Grand Total";
            if (this.needAverages) {
                this.totals.averages.caption = "Grand Average";
            }
        }


        return {
            data: page,
            columns: schema,
            grandTotals: this.totals && this.totals.totals || "",
            grandAverages: this.totals && this.totals.averages || "",
            pageTotals: this.data.length > this.pageSize ? pageTotals.totals || "" : "",
            pageAverages: this.data.length > this.pageSize ? pageTotals.averages || "" : "",
            filteredTotals: this.filteredTotals && this.filteredTotals.totals || "",
            filteredAverages: this.filteredTotals && this.filteredTotals.averages || "",
            "class": this.tableClassName,
            totalTop: this.user.table.top.printTotals,
            pagerTop: this.user.table.top.printPager,
            totalBottom: this.user.table.bottom.printTotals,
            pagerBottom: this.user.table.bottom.printPager
        };
    };


    this.renderTable = function () {
        this.render();
        FixedHeader.attachTo(this.select('tableSelector'));
        Ripple.attachTo(this.select('rippleSelector'));
        this.highlightFilterResults();
        this.trigger('requestUser');

        // this.select('containerSelector').html(this.render());
        // Lightbox.attachTo(this.select('imageLightboxSelector'));
        // TextBlobBox.attachTo(this.select('textBlobBoxSelector'));
        //LowResImage.attachTo(this.select('imageFieldSelector'));
    };


    this.getPage = function () {
        var list = [],
            i = this.pageSize * (this.page - 1),
            end = Math.min(i + this.pageSize, this.data.length);

        for (; i < end; i++) {
            list.push(this.data[i]);
        }

        return list;
    };

    this.normalize = function (data) {
        var offset = (new Date()).getTimezoneOffset() * 60000;
        dict.clear();

        data.forEach(line => {
            this.schema.forEach(col => {
                if (col.toRender !== false && line[col.data] !== null) {
                    var type = col.type.toUpperCase();

                    switch (type) {
                        case "DISPATCH":
                            line[col.data] = {
                                value: line[col.data],
                                time: line[col.data],
                                update: dateToDow(line[col.update]),
                                updateTimestamp: line[col.update],
                                text: dateToDow(line[col.data])
                            };
                            break;
                        case "DATE":
                            line[col.data] = {
                                value: line[col.data],
                                time: line[col.data],
                                text: mixam.dateToDateString(line[col.data])
                            };
                            break;
                        case "TIME":
                            line[col.data] = {
                                value: (line[col.data] - offset) % 8.64e+7,
                                time: (line[col.data] - offset) % 8.64e+7,
                                text: mixam.dateToTimeString(line[col.data])
                            };
                            break;
                        case "DATETIME":
                            line[col.data] = {
                                value: line[col.data],
                                time: line[col.data],
                                text: mixam.dateToDateTimeString(line[col.data])
                            };
                            break;
                        case "TIMEBOX":
                            line[col.data] = {
                                value: line[col.data],
                                time: line[col.data],
                                text: mixam.dateToDateTimeString(line[col.data])
                            };
                            break;

                        case "IMAGE":
                            if (typeof line[col.data] === "object") {
                                line[col.data] = {
                                    href: line[col.data].href,
                                    caption: line[col.data].caption || line[col.data].title || line[col.data].href,
                                    title: line[col.data].title || "",
                                    target: line[col.data].target || "_blank",
                                    src: line[col.data].src
                                };
                            }
                            break;
                        case "COMMAND":
                        case "TEXT":
                            line[col.data] = (line[col.data] || '').trim();
                            if (col.escape) {
                                //noinspection JSDeprecatedSymbols
                                line[col.data] = unescape(line[col.data]);
                            }
                            break;
                        case "HTML":
                            line[col.data] = {
                                value: line[col.data],
                                text: line[col.data].replace(reSanitize, "")
                            };
                            break;

                        case "LINK":
                            if (typeof line[col.data] === "object") {
                                line[col.data] = {
                                    href: line[col.data].href,
                                    caption: line[col.data].caption || line[col.data].title || line[col.data].href,
                                    title: line[col.data].title || "",
                                    target: line[col.data].target || "_blank"
                                };
                            } else {
                                line[col.data] = {
                                    href: line[col.data],
                                    caption: line[col.data],
                                    title: "",
                                    target: "_blank"
                                };
                            }
                            break;
                        case "ICON":
                            if (typeof line[col.data] === "object") {
                                line[col.data] = {
                                    href: line[col.data].href,
                                    icon: line[col.data].icon,
                                    caption: line[col.data].caption,
                                    title: line[col.data].title || "",
                                    target: line[col.data].target || "_blank",
                                    prefix: (line[col.data].icon || "").indexOf('fa-') === 0 ? "fa" : ""
                                };
                            } else {
                                line[col.data] = {
                                    href: "",
                                    caption: "",
                                    icon: line[col.data],
                                    title: "",
                                    target: "_blank",
                                    prefix: (line[col.data] || "").indexOf('fa-') === 0 ? "fa" : ""
                                };
                            }
                            break;
                        case "TEXT-BLOB" :
                            line[col.data] = {
                                fullText: line[col.data],
                                ref: dict.add(this.addComment(col) + line[col.data]),
                                text: (line[col.data] || "").substr(0, 100)
                            };
                            break;
                        case "THUMBNAIL":
                            if (typeof line[col.data] === "object") {
                                line[col.data] = {
                                    src: line[col.data].src,
                                    href: line[col.data].href,
                                    title: line[col.data].title || "",
                                    target: line[col.data].target || "_blank"
                                };
                            }
                            break;
                        case "USER":
                            if (typeof line[col.data] === "object") {
                                line[col.data].caption = line[col.data].name + " " + line[col.data].email;
                            }
                            break;
                        case "REMARKS":
                            break;
                    }
                }
            });
        });
    };


    this.addComment = function (col) {
        return `<div class="tit">/*****************************************</div><div class="tit"> ${col.title}</div><div class="tit">*****************************************/</div>\n`;
    };

    this.normalizeSchema = function () {
        return this.schema.map(col => {
            var colType = col.type.toUpperCase();

            col[colType] = true;
            if (colType === "NUMBER") {
                col.decimal = col.decimal || 0;
                /** @namespace col.sum */
                    //col.average = col.sum; //remove!!!!!!!
                this.needTotals = this.needTotals || col.sum || col.average;
                this.needAverages = this.needAverages || col.average;
            } else if (colType === "ICON") {
                col.size = col.size || 2;
            }
            if (nobleTypes.indexOf(colType) !== -1) {
                col.sortable = false;
            }
            return col;
        });
    };

    this.updateSchema = function () {
        var result = [];

        this.schema.forEach(col => {
            col = $.extend(true, {}, col);

            if (col.sortable !== false) {
                col.sortable = "0";

                if (this.sort && this.sort[col.data]) {
                    col.sortKey = this.sort[col.data];
                } else {
                    delete col.sortKey;
                }

            } else {
                delete col.sortable;
            }

            if (col.type === "thumbnail" || col.type === "lightbox") {
                delete col.sortable;
            }
            col.defaultSortOrder = 1;
            if (col.type === "date" || col.type === "datetime" || col.type === "dispatch" || col.type === "time" || col.type === "timebox" || col.type === "remarks") {
                col.defaultSortOrder = -1;
            }
            result.push(col);

            if (this.isComparing && this.compare) {

                if (this.compare.key.find(x => col.data === x)) {
                    col['header-class'] = "compare-source";
                    col['class'] = "compare-source";
                }

                if (this.compare.columns.find(x => col.data === x)) {
                    ["sum", "percent"].forEach(mode => {
                        if (this.compareParams.display[mode]) {
                            let colComp = $.extend(true, {}, col);

                            colComp.data = colComp.data + "_COMP" + (mode === "percent" ? "_PERCENT" : "");
                            colComp['header-class'] = "compare-dupe";
                            colComp['class'] = "compare-dupe";

                            if (mode === "percent") {
                                colComp.title = colComp.title.substr(0, 3) + "%";
                                colComp.append = "%";
                                if (col.sum) {
                                    colComp.sum = false;
                                }
                            }

                            if (this.sort && this.sort[colComp.data]) {
                                colComp.sortKey = this.sort[colComp.data];
                            } else {
                                delete colComp.sortKey;
                            }
                            result.push(colComp);
                        }
                    });
                }
            }
        });

        return result;
    };

    this.calcTotals = function (data, schema) {
        var totals,
            averages;


        if (this.needTotals) {
            totals = data.reduce((previousValue, currentValue) => {
                var o = {};

                schema.forEach(col => {
                    if (col.sum || col.average) {
                        o[col.data] = (previousValue[col.data] || 0) + (currentValue[col.data] || 0);
                    }
                });
                return o;

            }, {});

            if (this.needAverages) {
                averages = {};
                Object.keys(totals).forEach(k => averages[k] = totals[k] / data.length);
            }

            if (this.compareMap) {
                totals.override = {td: {}};
                this.compare.columns.forEach(col => {
                    let derivative = "none-derivative",
                        upSurfix = this.compare.success > 0 ? "success" : "failure",
                        downSurfix = this.compare.success > 0 ? "failure" : "success";

                    totals[col + "_COMP_PERCENT"] = Math.round(totals[col + "_COMP"] / (totals[col] - totals[col + "_COMP"]) * 10000) / 100;
                    if (totals[col + "_COMP"] < 0) {
                        derivative = `neg-derivative derivative-${downSurfix}`;
                    } else if (totals[col + "_COMP"] > 0) {
                        derivative = `pos-derivative derivative-${upSurfix}`;
                    }

                    totals.override.td[col + "_COMP"] = derivative;
                    totals.override.td[col + "_COMP_PERCENT"] = derivative;
                });
            }
        }
        return {
            totals: totals,
            averages: averages
        };
    };

    this.calcPageTotals = function (data, schema) {
        return this.calcTotals(data, schema);
    };

    this.addTotals = function (data) {
        this.totals = this.calcTotals(data, this.updateSchema());
    };

    this.addFilterTotals = function (data) {
        this.filteredTotals = this.calcTotals(data, this.updateSchema());
    };

    this.setSchema = function (cols) {
        this.schema = [];
        cols.forEach(col => {
            /** @namespace col.toRender */
            if (col.toRender !== false) {
                this.schema.push(col);
                this.schema[col.data] = col;
            }
        });
        this.schema = this.normalizeSchema();
    };

    this.setPageNumber = function (event, data) {
        this.page = data.page;
        setTimeout(() => {
            this.renderTable();
            this.trigger("uiSetPageNumber", {page: this.page});
            this.trigger("uiAfterTableRender", {table: this.select('containerSelector').find("table")});
        }, 10);
    };

    this.sortClick = function (event) {
        var $target = $(event.target).closest("th"),
            type = $target.data("sortType"),
            key = $target.data("sortKey"),
            order = +$target.attr('data-sort-order'); //$target.data("sortOrder");

        if (this.sort[key]) {
            order *= -1;
        }
        this.sortData(type, key, order);
        this.page = 1;

        setTimeout(() => {
            this.renderTable();
            this.trigger("uiSetPageNumber", {page: this.page});
            this.trigger("uiAfterTableRender", {table: this.select('containerSelector').find("table")});
        }, 10);
    };

    this.requestSortClick = function (event, data) {
        this.sortClick({target: data.th});
    };

    this.sortData = function (type, key, order) {
        var sorter,
            sort = {};

        if (sorters[type]) {
            sorter = sorters[type][order === 1 ? "asc" : "desc"](key);
            //noinspection JSValidateTypes
            this.sort = {};
            this.sort[key] = order;
            this.data.sort(sorter);
            sort[key] = order;
            this.trigger("uiAfterSort", sort);
        }
    };

    this.sortDataByOption = function (option) {
        var key = Object.keys(option)[0],
            value = option[key],
            column = this.schema.filter(column => column.data === key)[0];

        if (column) {
            this.sortData(column.type, key, value);
        } else {
            if (console) {
                console.log(`Sort key '${key}' is not found in shema`);
            }
        }
    };

    this.setData = function (event, data) {
        this.page = 1;
        this.sort = data["default-sort"];
        this.filter = null;
        this.trigger("requestState");

        this.csvFile = data["csv-file"];
        this.compare = data.compare;

        /** @namespace data.columns */
        this.setSchema(data.columns);
        this.filters = data.filters;
        this.tableClassName = data.class;
        this.updateData(data.response);
        if (data.pageSize) {
            this.pageSize = data.pageSize;
        }
        this.data = this.getOriginalData();
        if (this.sort) {
            this.sortDataByOption(this.sort);
        }

        this.select('filterBoxSelector').removeClass("hidden");
        this.select('pagerSelector').removeClass("hidden");
        this.trigger("uiSetPageSize", {size: this.pageSize});

        //noinspection JSValidateTypes

        this.trigger("uiSetData", {list: this.data, csvFile: this.csvFile, compare: this.compare});

        this.trigger("uiSetPageNumber", {page: this.page});

        this.trigger("uiBeforeTableRender", {columns: this.updateSchema(), filters: this.filters});
        this.renderTable();
        this.trigger("uiAfterTableRender", {table: this.select('containerSelector').find("table")});
    };

    this.setUpdate = function (event, data) {
        this.updateData(data.response);


        this.trigger("uiSetData", {list: this.data, csvFile: this.csvFile, compare: this.compare});

        if (this.activeFilter) {
            this.startFilter(this.activeFilter);
        } else {
            this.data = this.getOriginalData();
            if (this.sort) {
                this.sortDataByOption(this.sort);
            }
            this.trigger("uiBeforeTableRender", {columns: this.updateSchema(), filters: this.filters});
            this.renderTable();
            this.trigger("uiAfterTableRender", {table: this.select('containerSelector').find("table")});
        }
    };

    this.updateData = function (response) {
        this.originalData = response;
        this.normalize(this.originalData);
        this.data = this.getOriginalData();
    };


    this.setFailure = function (event, data) {
        this.select('containerSelector').html(this.getResponseError(data));
    };

    this.getResponseError = function (data) {
        var error = data.status,
            fileName = 'File',
            errorText = data.statusText;

        return `<div class=error><h2>Server returns error: ${error}</h2><p>${fileName} ${errorText}</p></div>`;
    };

    this.filterChange = function (event, data) {
        if (data === undefined) {
            this.stopFilter();
        } else {
            this.startFilter(data);
        }
    };

    this.compileFilterLambda = function (key, filter, schema) {
        var col = this.getSchemaColumn(key, schema),
            func = null;

        if (col) {
            Object.keys(filter).forEach(operator => {
                switch (col.type.toUpperCase()) {
                    case "HTML":
                    case "LINK":
                    case "TEXT-BLOB":
                    case "ICON":
                    case "USER":
                    case "COMMAND":
                    case "TEXT":
                        func = compileTextFilter(operator, filter[operator]);
                        break;
                    case "NUMBER":
                        func = compileNumberFilter(operator, filter[operator]);
                        break;
                    case "BOOLEAN":
                    case "PROOF":
                        func = compileBooleanFilter(filter[operator]);
                        break;
                    case "TIME":
                        func = compileTimeFilter(operator, filter[operator]);
                        break;
                    case "TIMEBOX":
                    case "DATETIME":
                    case "DISPATCH":
                    case "DATE":
                        func = compileDateFilter(operator, filter[operator]);
                        break;
                }
            });
        }
        return func;
    };

    this.startFilter = function (filter) {
        var filterators = {},
            schema = this.updateSchema();

        this.activeFilter = filter;
        if (Object.keys(filter.and).length) {
            Object.keys(filter.and).forEach(key => filterators[key] = this.compileFilterLambda(key, filter.and[key], schema));
        }

        if (Object.keys(filter.or).length) {
            Object.keys(filter.or).forEach(key => filterators[key] = this.compileFilterLambda(key, filter.or[key], schema));
        }

        this.data = this.getOriginalData().filter(line => {
            var anded = true,
                ored = true;

            if (Object.keys(filter.and).length) {
                anded = Object.keys(filter.and).every(key => filterators[key](line[key] ? line[key].time || line[key].icon || line[key].fullText || line[key].text || line[key].caption || line[key].title || line[key] || "" : ""));
            }

            if (Object.keys(filter.or).length) {
                ored = Object.keys(filter.or).some(key => filterators[key](line[key] ? line[key].time || line[key].icon || line[key].fullText || line[key].text || line[key].caption || line[key].title || line[key] || "" : ""));
            }
            return anded && ored;
        });

        if (this.needTotals) {
            this.addFilterTotals(this.data);
        }

        if (this.sort) {
            this.sortDataByOption(this.sort);
        }
        this.page = 1;
        this.trigger("uiSetData", {list: this.data, csvFile: this.csvFile, compare: this.compare});
        this.trigger("uiSetPageNumber", {page: this.page});
        this.trigger("uiSetFilter", filter);
        this.renderTable();
        this.trigger("uiAfterTableRender", {table: this.select('containerSelector').find("table")});
    };

    this.highlightFilterResults = function () {
        var allFilters;

        if (this.activeFilter) {
            allFilters = $.extend({}, this.activeFilter.and, this.activeFilter.or);

            this.runUnblocking(this.select('containerSelector').find("table tbody tr"), function (tr) {
                if (!tr) {
                    return;
                }

                this.schema.forEach((col, i) => {
                    var re,
                        operator,
                        colTypeUpper = col.type.toUpperCase();

                    if (allFilters[col.data]) {
                        operator = Object.keys(allFilters[col.data])[0];

                        if (colTypeUpper === "TEXT" || colTypeUpper === "LINK" || colTypeUpper === "HTML" || colTypeUpper === "USER") {
                            if (operator === "cn") {
                                re = new RegExp("(" + this.sanitaizeRegexp(allFilters[col.data][operator]) + ")", "gi");
                            } else if (operator === "regexp") {
                                re = new RegExp("(" + allFilters[col.data][operator] + ")", "gi");
                            }
                            if (re) {
                                getTextNodesIn(tr.cells[i], true).forEach(node => {
                                    var span;

                                    if (re.test(node.nodeValue)) {
                                        span = document.createElement("span");
                                        span.innerHTML = node.nodeValue.replace(re, "<span class='highlight'>$1</span>");
                                        node.parentNode.replaceChild(span, node);
                                    }
                                });
                            }
                        } else if (colTypeUpper === "TEXT-BLOB") {
                            let $td = $(tr.cells[i]),
                                word = allFilters[col.data][operator];

                            $td
                                .addClass('highlight')
                                .attr("data-filter", this.sanitaizeRegexp(word))
                                .find('.text-blob-sample')
                                .html(this.getFilteredBlobSample($td, word));
                        }
                    }
                });

            }, this); // runUnblocking
        }
    };

    this.getFilteredBlobSample = function ($td, word) {
        var re = new RegExp("(" + word + ")", "gi"),
            ref = $td.find('[data-type="text-blob"]').data('ref'),
            text = dict.get(ref),
            a = re.exec(text);

        if (a) {
            let start = Math.max(a.index - 20, 0);
            return text.substr(start, 100).replace(re, "<span class='highlight'>$1</span>");
        }
        return $td.find('.text-blob-sample').text();
    };

    this.getSchemaColumn = function (id, schema) {
        var i, col;

        for (i = 0; col = schema[i]; i++) { // jshint ignore:line
            if (col.data === id) {
                return col;
            }
        }
    };

    this.setState = function (event, state) {
        this.sort = state.sort || this.sort;
        this.page = state.page || this.page || 1;
    };

    this.sanitaizeRegexp = function (text) {
        return text.replace(/(\*|\.|\(|\)|\$|\[|\]|\{|\})/g, "\\$1");
    };

    this.stopFilter = function () {
        if (this.activeFilter) {
            this.activeFilter = false;
            this.page = 1;
            this.refreshDisplay();
        }
    };

    this.refreshDisplay = function () {
        this.data = this.getOriginalData();
        if (this.sort) {
            this.sortDataByOption(this.sort);
        }
        this.trigger("uiSetData", {list: this.data, csvFile: this.csvFile});
        this.trigger("uiSetPageNumber", {page: this.page});
        this.trigger("uiClearFilter");
        this.renderTable();
        this.trigger("uiAfterTableRender", {table: this.select('containerSelector').find("table")});
    };

    this.runCompare = function (data) {
        this.compareMap = null;

        if (this.compare) {
            this.compareMap = {};
            this.compareParams = data.request;
            this.normalize(data.response);
            data.response.forEach(item => {
                let key = this.compare.key.reduce((p, c) => p + (item[c].href || item[c].value || item[c]), "");

                this.compareMap[key] = {};
                this.compare.columns.forEach(col => this.compareMap[key][col] = item[col]);
            });
            this.isComparing = true;

            this.trigger("uiBeforeTableRender", {columns: this.updateSchema(), filters: this.filters});
            this.afterCompareChange();
            this.trigger("uiSetCompare", this.compareParams);

        }
    };

    this.getOriginalData = function () {
        var results = this.originalData.slice();

        this.filteredTotals = null;
        if (this.isComparing && this.compare) {
            results = results.map(item => {
                let key = this.compare.key.reduce((p, c) => p + (item[c].href || item[c].value || item[c]), "");

                item.override = item.override || {};
                item.override.td = item.override.td || {};

                if (this.compareMap) {
                    this.compare.columns.forEach(col => {
                        let derivative = "none-derivative",
                            a = item[col],
                            upSurfix = this.compare.success > 0 ? "success" : "failure",
                            downSurfix = this.compare.success > 0 ? "failure" : "success",
                            b = this.compareMap[key] && this.compareMap[key][col];

                        if (b) {
                            item[col + "_COMP"] = a - b;
                            item[col + "_COMP_PERCENT"] = Math.round((a - b) / b * 10000) / 100;
                            if (a > b) {
                                derivative = `pos-derivative derivative-${upSurfix}`;
                            } else if (b > a) {
                                derivative = `neg-derivative derivative-${downSurfix}`;
                            }
                        }
                        item.override.td[col + "_COMP"] = derivative;
                        item.override.td[col + "_COMP_PERCENT"] = derivative;
                    });
                }
                return item;
            });
        }
        if (this.needTotals) {
            this.addTotals(results);
        }
        return results;
    };

    this.setCompareData = function (event, data) {
        setTimeout($.proxy(this.runCompare, this, data), 20);
    };

    this.afterCompareChange = function () {
        // maintain filter state if exists
        if (this.activeFilter) {
            this.startFilter(this.activeFilter);
        } else {
            this.refreshDisplay();
        }
    };

    this.afterUserChanged = function () {
        this.afterCompareChange();
    };

    this.resetCompareData = function (event) {
        if (this.isComparing) {
            this.compareMap = null;
            this.isComparing = false;
            this.trigger("uiSetCompare");
            this.trigger("uiBeforeTableRender", {columns: this.updateSchema(), filters: this.filters});
            this.afterCompareChange();
        }
    };

    this.setUser = function (event, data) {
        this.user = data;
    };

    this.after('initialize', function () {
        State.attachTo($(document));
        sorters.enum = sorters.number;

        this.sort = {};
        this.pageSize = this.getParameter("pagesize") || 50;
        this.on(document, "uiDataReady", this.setData);
        this.on(document, "uiDataUpdate", this.setUpdate);
        this.on(document, "uiDataFailure", this.setFailure);
        this.on(document, "uiRequestPageChange", this.setPageNumber);
        this.on(document, "uiFilterChange", this.filterChange);
        this.on(document, "uiSetState", this.setState);
        this.on(document, "uiCompareDataReady", this.setCompareData);
        this.on(document, "uiCompareReset", this.resetCompareData);
        this.on(document, "requestSortClick", this.requestSortClick);
        this.on(document, "notifyUserDetails", this.setUser);
        this.on(document, "requestNotifyUserChange", this.afterUserChanged);

        DataPager.attachTo(this.select('pagerSelector'));
        FloatingToolbarButton.attachTo(this.select('buttonSelector'));

        this.on("click", {
            sortHeaderSelector: Utils.debounce(this.sortClick, 200)
        });
        this.trigger("requestUser");


    });
}