import mixam from '../../../boot/mixam';
import defineComponent from '../../../../components/flight/lib/component';
import DataTable from '../../table/data-table-net/data-table-net.tsx';
import axios from "axios";

export default defineComponent(ShopifyActiveProducts);

function ShopifyActiveProducts() {

    this.attributes({
        url: "/api/admin/shopify/active-products",
        dataTableSelector: '[data-type="data-table"]',
        syncButtonSelector: '.sync-button',
        syncProductsUrl: "/api/admin/shopify/products/sync",
    });

    this.createSchema = function () {
        const cols = [];

        cols.push({
            title: "Order",
            type: "link",
            data: "order"
        });
        cols.push({
            title: "Shop Domain",
            type: "string",
            data: "shopifyShopDomain"
        });
        cols.push({
            title: "Title",
            type: "string",
            data: "title"
        });
        return cols;
    };


    this.paint = function () {
        const data = {
            columns: this.createSchema(),
            fileName: this.attr.url.split("/").pop(),
            response: this.normalize(this.data)
        };

        if (this.dataReadyFired) {
            window.dispatchEvent(new CustomEvent('uiDataUpdate', { detail: data }));
        } else {
            this.dataReadyFired = true;
            this.trigger("uiDataReady", data);
        }
    };

    this.normalize = function (data) {
        return data.map(line => ({
            shopifyShopDomain: line.shopifyShopDomain,
            order: {
                href: `${mixam.secureDomain}/orders/${line.orderId}`,
                target: "_blank",
                caption: line.caseNumber
            },
            title: line.publicationConfig.title
        }));
    };


    this.getData = function () {
        $.getJSON(this.attr.url)
            .done(data => {
                this.data = data;
                this.paint();
            })
            .fail((err) => this.trigger("log", {message: err}));
    };

    this.handleSyncAllProducts = function () {
        window.dispatchEvent(
            new CustomEvent(
                'showFixedMessage',
                { detail: { message: { type: 'info', body: 'Syncing Publications with Shopify', interval: 2000}}}
            )
        );
        axios.put(this.attr.syncProductsUrl)
            .then(() => {
                window.dispatchEvent(
                    new CustomEvent(
                        'showFixedMessage',
                        { detail: { message: { type: 'success', body: 'Successfully synced all publications to Shopify', interval: 3000}}}
                    )
                );
            }).catch(() => {
            window.dispatchEvent(
                new CustomEvent(
                    'showFixedMessage',
                    { detail: { message: { type: 'error', body: 'Failed to sync all publications to Shopify', interval: 3000}}}
                )
            );
        });
    };

    this.after('initialize', function () {
        DataTable.attachTo(this.select('dataTableSelector'));
        setTimeout(() => this.getData(), 10);

        this.on('click', {
            syncButtonSelector: this.handleSyncAllProducts,
        });
    });
}
