import defineComponent from '../../../../components/flight/lib/component';
import React from 'react';
import ReactDom from 'react-dom/client';
import CsvDataImporter from '../React/CsvDataImporter/CsvDataImporter.tsx';
import { ImporterField } from 'react-csv-importer';

export default defineComponent(AdminAccountsPayableDataImporter);

const fields = [
    {
        Component: ImporterField,
        name: 'orderNumber',
        label: 'Order Number'
    },
    {
        Component: ImporterField,
        name: 'actualCost',
        label: 'Actual Cost'
    },
    {
        Component: ImporterField,
        name: 'referenceNumber',
        label: 'Supplier Reference',
        optional: true
    },
    {
        Component: ImporterField,
        name: 'overwrite',
        label: 'Overwrite',
        optional: true
    }
];

function AdminAccountsPayableDataImporter() {

    this.initDataImporter = function () {

        const dataImporterContainer = $(this.node);

        const root = ReactDom.createRoot(dataImporterContainer.get(0));
        root.render(
            <CsvDataImporter
                fields={fields}
                uploadDataUrl='/api/admin/accounts-payable'
                chunkSize={50}
            />
        );
    };

    this.after('initialize', function () {
        this.initDataImporter();
    });

}
