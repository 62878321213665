import React, { useEffect, useRef } from 'react';
import 'bootstrap-daterangepicker/daterangepicker.css';
import 'bootstrap-daterangepicker';
var DateRangePicker = function (_a) {
    var startDate = _a.startDate, endDate = _a.endDate, onDateChange = _a.onDateChange, _b = _a.placeholder, placeholder = _b === void 0 ? 'Select date range...' : _b;
    var inputRef = useRef(null);
    useEffect(function () {
        var $input = $(inputRef.current);
        $input.daterangepicker({
            autoUpdateInput: false,
            locale: {
                cancelLabel: 'Clear',
                format: 'DD-MM-YYYY'
            }
        });
        $input.on('apply.daterangepicker', function (ev, picker) {
            onDateChange(picker.startDate.toDate(), picker.endDate.toDate());
            $input.val(picker.startDate.format('DD-MM-YYYY') + ' - ' + picker.endDate.format('DD-MM-YYYY'));
        });
        $input.on('cancel.daterangepicker', function () {
            onDateChange(null, null);
            $input.val('');
        });
        // Cleanup
        return function () {
            var picker = $input.data('daterangepicker');
            if (picker) {
                picker.remove();
            }
        };
    }, [onDateChange]);
    useEffect(function () {
        var $input = $(inputRef.current);
        if (startDate && endDate) {
            var start = new Date(startDate).toLocaleDateString('en-GB');
            var end = new Date(endDate).toLocaleDateString('en-GB');
            $input.val("".concat(start, " - ").concat(end));
        }
        else {
            $input.val('');
        }
    }, [startDate, endDate]);
    return (React.createElement("input", { type: "text", className: "form-control", ref: inputRef, placeholder: placeholder }));
};
export default DateRangePicker;
