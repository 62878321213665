var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useState, useRef } from 'react';
import { Alert, Button, Card, Col, Form, Modal, Row, Spinner, Table } from 'react-bootstrap';
import { Formik, Form as FormikForm, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import ConfirmationModal from './ConfirmationModal';
var ItemTypes = {
    ROW: 'row'
};
var validationSchema = Yup.object().shape({
    enabled: Yup.boolean(),
    shrinkWrapOptions: Yup.array()
        .when('enabled', {
        is: true,
        then: function (schema) {
            return schema
                .min(1, 'At least 1 option is required when shrink wrap is enabled')
                .test('one-default', 'At least one shrink-wrap option must be set as default', function (options) {
                return !!options && options.some(function (opt) { return opt.santaDefault; });
            });
        },
        otherwise: function (schema) { return schema; }
    })
        .of(Yup.object().shape({
        substrateType: Yup.string().required('Substrate type is required.'),
        substrateWeightId: Yup.number()
            .required('Weight ID is required.')
            .min(1, 'Weight ID must be > 0'),
        bundleMinimum: Yup.number().required('Bundle minimum is required.'),
        bundleIncrement: Yup.number()
            .min(1, 'Must be at least 1')
            .required('Bundle increment is required.'),
        bundleDefault: Yup.number()
            .required('Bundle default is required.')
            .test('test-default>=min', 'Default must be >= minimum', function (value) {
            var bundleMinimum = this.parent.bundleMinimum;
            return value >= bundleMinimum;
        })
            .test('test-default-multiple-of-increment', 'Default must be a multiple of the increment', function (value) {
            var bundleIncrement = this.parent.bundleIncrement;
            return value % bundleIncrement === 0;
        }),
        capacity: Yup.object().shape({
            x: Yup.number().min(1).required('Capacity X is required.'),
            y: Yup.number().min(1).required('Capacity Y is required.'),
            z: Yup.number().min(1).required('Capacity Z is required.')
        }),
        santaDefault: Yup.boolean()
    }))
});
function getAvailableWeights(substrateTypeKey, colorKey, allSubstrateTypes) {
    if (!substrateTypeKey || !colorKey)
        return [];
    var substrateTypeObj = allSubstrateTypes.find(function (st) { return st.substrateType === substrateTypeKey; });
    if (!substrateTypeObj)
        return [];
    var colorObj = substrateTypeObj.substrateColours.find(function (c) { return c.substrateColour === colorKey; });
    if (!colorObj)
        return [];
    return colorObj.weights || [];
}
function inferColorFromWeightId(substrateType, weightId, substrateTypesList) {
    var subTypeObj = substrateTypesList.find(function (st) { return st.substrateType === substrateType; });
    if (!subTypeObj)
        return '';
    for (var _i = 0, _a = subTypeObj.substrateColours; _i < _a.length; _i++) {
        var colorObj = _a[_i];
        if (colorObj.weights.some(function (w) { return w.id === weightId; })) {
            return colorObj.substrateColour;
        }
    }
    return '';
}
var ShrinkWrapMetaDataEditor = function (_a) {
    var _b, _c;
    var productId = _a.productId, subProductId = _a.subProductId, santaType = _a.santaType, substrateTypes = _a.substrateTypes;
    var _d = useState(false), isLoading = _d[0], setLoading = _d[1];
    var _e = useState(null), error = _e[0], setError = _e[1];
    var _f = useState(null), successMessage = _f[0], setSuccessMessage = _f[1];
    var _g = useState(null), metadataDocumentWrapper = _g[0], setMetadataDocumentWrapper = _g[1];
    var _h = useState(false), useCustom = _h[0], setUseCustom = _h[1];
    var _j = useState(false), showModal = _j[0], setShowModal = _j[1];
    var _k = useState(null), editIndex = _k[0], setEditIndex = _k[1];
    var _l = useState({
        substrateType: '',
        colour: '',
        weight: '',
        bundleIncrement: 1,
        bundleMinimum: 1,
        bundleDefault: 1,
        capacityX: 1,
        capacityY: 1,
        capacityZ: 1
    }), modalFields = _l[0], setModalFields = _l[1];
    var _m = useState(false), showConfirmationModal = _m[0], setShowConfirmationModal = _m[1];
    useEffect(function () {
        setLoading(true);
        axios
            .get("/api/admin/metadata/product/shrink-wrap/products/".concat(productId, "/sub-products/").concat(subProductId, "/santa-types/").concat(santaType))
            .then(function (response) {
            setMetadataDocumentWrapper(response.data);
            if (subProductId !== 0) {
                setUseCustom(response.data.productMetadataDocument !== null);
            }
            setLoading(false);
        })
            .catch(function () {
            setLoading(false);
            setError('Failed to load shrink wrap metadata document.');
        });
    }, [productId, subProductId, santaType]);
    var activeDocument = useCustom
        ? metadataDocumentWrapper === null || metadataDocumentWrapper === void 0 ? void 0 : metadataDocumentWrapper.productMetadataDocument
        : metadataDocumentWrapper === null || metadataDocumentWrapper === void 0 ? void 0 : metadataDocumentWrapper.defaultProductMetadataDocument;
    var isEditable = subProductId === 0 || useCustom;
    var initialValues = {
        enabled: (_b = activeDocument === null || activeDocument === void 0 ? void 0 : activeDocument.enabled) !== null && _b !== void 0 ? _b : false,
        shrinkWrapOptions: (_c = activeDocument === null || activeDocument === void 0 ? void 0 : activeDocument.options) !== null && _c !== void 0 ? _c : []
    };
    var saveShrinkWrapsMetadata = function (values, setSubmitting) {
        var _a;
        setLoading(true);
        setError(null);
        setSuccessMessage(null);
        var newDocument = activeDocument
            ? __assign({}, activeDocument) : {
            shopId: (_a = metadataDocumentWrapper === null || metadataDocumentWrapper === void 0 ? void 0 : metadataDocumentWrapper.defaultProductMetadataDocument) === null || _a === void 0 ? void 0 : _a.shopId,
            productId: productId,
            subProductId: subProductId,
            santaType: santaType
        };
        var saveRequest = {
            useDefault: !useCustom,
            shrinkWrapMetadataDocument: __assign(__assign({}, newDocument), { enabled: values.enabled, options: values.shrinkWrapOptions })
        };
        axios
            .post("/api/admin/metadata/product/shrink-wrap/products/".concat(productId, "/sub-products/").concat(subProductId, "/santa-types/").concat(santaType), saveRequest)
            .then(function (response) {
            setMetadataDocumentWrapper(response.data);
            setLoading(false);
            setSubmitting(false);
            setSuccessMessage('Shrink Wrap Metadata saved successfully!');
        })
            .catch(function (err) {
            setLoading(false);
            setSubmitting(false);
            setError("Failed to save Shrink Wrap doc: ".concat(err.message));
        });
    };
    var handleSave = function (values, _a) {
        var setSubmitting = _a.setSubmitting;
        if (!useCustom && subProductId !== 0) {
            setShowConfirmationModal(true);
        }
        else {
            saveShrinkWrapsMetadata(values, setSubmitting);
        }
    };
    var handleCopyFromDefault = function () {
        var _a, _b, _c, _d;
        if (!(metadataDocumentWrapper === null || metadataDocumentWrapper === void 0 ? void 0 : metadataDocumentWrapper.defaultProductMetadataDocument))
            return;
        var defaultDoc = metadataDocumentWrapper.defaultProductMetadataDocument;
        var currentCustomDoc = metadataDocumentWrapper.productMetadataDocument;
        var updatedCustomDoc = __assign(__assign({}, currentCustomDoc), { shopId: (_a = currentCustomDoc === null || currentCustomDoc === void 0 ? void 0 : currentCustomDoc.shopId) !== null && _a !== void 0 ? _a : defaultDoc.shopId, productId: (_b = currentCustomDoc === null || currentCustomDoc === void 0 ? void 0 : currentCustomDoc.productId) !== null && _b !== void 0 ? _b : productId, subProductId: (_c = currentCustomDoc === null || currentCustomDoc === void 0 ? void 0 : currentCustomDoc.subProductId) !== null && _c !== void 0 ? _c : subProductId, santaType: (_d = currentCustomDoc === null || currentCustomDoc === void 0 ? void 0 : currentCustomDoc.santaType) !== null && _d !== void 0 ? _d : santaType, enabled: defaultDoc.enabled, options: __spreadArray([], (defaultDoc.options || []), true) });
        setMetadataDocumentWrapper(function (prev) {
            return prev
                ? __assign(__assign({}, prev), { productMetadataDocument: updatedCustomDoc }) : prev;
        });
    };
    var moveRow = function (type, dragIndex, hoverIndex, setFieldValue, values) {
        var updated = __spreadArray([], values[type], true);
        var removed = updated.splice(dragIndex, 1)[0];
        updated.splice(hoverIndex, 0, removed);
        setFieldValue(type, updated);
    };
    var DraggableRow = function (_a) {
        var index = _a.index, type = _a.type, option = _a.option, setFieldValue = _a.setFieldValue, values = _a.values;
        var ref = useRef(null);
        var _b = useDrop({
            accept: ItemTypes.ROW,
            hover: function (item) {
                if (!ref.current)
                    return;
                var dragIndex = item.index;
                var hoverIndex = index;
                if (item.type !== type || dragIndex === hoverIndex)
                    return;
                moveRow(type, dragIndex, hoverIndex, setFieldValue, values);
                item.index = hoverIndex;
            }
        }), drop = _b[1];
        var _c = useDrag({
            type: ItemTypes.ROW,
            item: { type: type, index: index },
            collect: function (monitor) { return ({
                isDragging: monitor.isDragging()
            }); }
        }), isDragging = _c[0].isDragging, drag = _c[1];
        drag(drop(ref));
        var handleDefaultChange = function (idx) {
            var updated = values.shrinkWrapOptions.map(function (opt, i) { return (__assign(__assign({}, opt), { santaDefault: i === idx })); });
            setFieldValue(type, updated);
        };
        var handleRemoveOption = function (idx) {
            var updated = values.shrinkWrapOptions.filter(function (_, i) { return i !== idx; });
            setFieldValue(type, updated);
        };
        var capacity = option.capacity || { x: 0, y: 0, z: 0 };
        return (React.createElement("tr", { ref: ref, style: { opacity: isDragging ? 0.5 : 1 } },
            React.createElement("td", null, option.substrateType),
            React.createElement("td", null, option.bundleIncrement),
            React.createElement("td", null, option.bundleMinimum),
            React.createElement("td", null, option.bundleDefault),
            React.createElement("td", null, capacity.x),
            React.createElement("td", null, capacity.y),
            React.createElement("td", null, capacity.z),
            React.createElement("td", null,
                React.createElement(Form.Check, { type: "radio", name: "defaultShrinkWrap", checked: option.santaDefault, onChange: function () { return handleDefaultChange(index); }, disabled: !isEditable })),
            React.createElement("td", { className: "text-end" },
                React.createElement(Button, { className: "btn-sm me-2", variant: "primary", disabled: !isEditable, onClick: function () { return handleEditShrinkWrap(index, values); } }, "Edit"),
                React.createElement(Button, { className: "btn-sm", variant: "danger", disabled: !isEditable, onClick: function () { return handleRemoveOption(index); } }, "Remove"))));
    };
    var handleAddShrinkWrap = function () {
        setEditIndex(null);
        setModalFields({
            substrateType: '',
            colour: '',
            weight: '',
            bundleIncrement: 1,
            bundleMinimum: 1,
            bundleDefault: 1,
            capacityX: 1,
            capacityY: 1,
            capacityZ: 1
        });
        setShowModal(true);
        setError(null);
    };
    var handleEditShrinkWrap = function (index, values) {
        setEditIndex(index);
        var existing = values.shrinkWrapOptions[index];
        var inferredColor = inferColorFromWeightId(existing.substrateType, existing.substrateWeightId, substrateTypes);
        setModalFields({
            substrateType: existing.substrateType,
            colour: inferredColor,
            weight: existing.substrateWeightId.toString(),
            bundleIncrement: existing.bundleIncrement,
            bundleMinimum: existing.bundleMinimum,
            bundleDefault: existing.bundleDefault,
            capacityX: existing.capacity.x,
            capacityY: existing.capacity.y,
            capacityZ: existing.capacity.z
        });
        setShowModal(true);
        setError(null);
    };
    var handleModalSave = function (setFieldValue, values) {
        var substrateType = modalFields.substrateType, colour = modalFields.colour, weight = modalFields.weight, bundleIncrement = modalFields.bundleIncrement, bundleMinimum = modalFields.bundleMinimum, bundleDefault = modalFields.bundleDefault, capacityX = modalFields.capacityX, capacityY = modalFields.capacityY, capacityZ = modalFields.capacityZ;
        var newOption = {
            substrateType: substrateType,
            substrateWeightId: Number(weight),
            bundleIncrement: bundleIncrement,
            bundleMinimum: bundleMinimum,
            bundleDefault: bundleDefault,
            santaDefault: activeDocument.options.length < 1,
            capacity: {
                x: capacityX,
                y: capacityY,
                z: capacityZ
            }
        };
        if (editIndex !== null) {
            // Update existing
            var updated = __spreadArray([], values.shrinkWrapOptions, true);
            updated[editIndex] = newOption;
            setFieldValue('shrinkWrapOptions', updated);
        }
        else {
            // Add new
            setFieldValue('shrinkWrapOptions', __spreadArray(__spreadArray([], values.shrinkWrapOptions, true), [
                newOption
            ], false));
        }
        setShowModal(false);
        setError(null);
    };
    var handleSubstrateTypeChange = function (newType) {
        setModalFields(function (prev) { return (__assign(__assign({}, prev), { substrateType: newType, colour: '', weight: '' })); });
    };
    var handleColorChange = function (newColor) {
        setModalFields(function (prev) { return (__assign(__assign({}, prev), { colour: newColor, weight: '' })); });
    };
    if (isLoading) {
        return React.createElement(Spinner, { animation: "grow", variant: "primary" });
    }
    if (!metadataDocumentWrapper) {
        return React.createElement(Alert, { variant: "danger" }, "No Shrink Wrap Metadata available");
    }
    return (React.createElement(DndProvider, { backend: HTML5Backend },
        React.createElement(Formik, { initialValues: initialValues, enableReinitialize: true, validationSchema: validationSchema, onSubmit: handleSave }, function (_a) {
            var _b;
            var values = _a.values, setFieldValue = _a.setFieldValue, isSubmitting = _a.isSubmitting, errors = _a.errors, touched = _a.touched, setSubmitting = _a.setSubmitting;
            return (React.createElement(React.Fragment, null,
                React.createElement(Card, { className: "p-4 shadow-sm bg-light" },
                    React.createElement(FormikForm, null,
                        React.createElement("h5", { className: "mb-4" }, "Shrink Wrap Metadata"),
                        successMessage && (React.createElement(Alert, { variant: "success", onClose: function () { return setSuccessMessage(null); }, dismissible: true }, successMessage)),
                        error && (React.createElement(Alert, { variant: "danger", onClose: function () { return setError(null); }, dismissible: true }, error)),
                        subProductId !== 0 && (React.createElement(React.Fragment, null,
                            React.createElement(Form.Group, null,
                                React.createElement(Form.Check, { type: "radio", label: "Use Default Metadata", checked: !useCustom, onChange: function () { return setUseCustom(false); } }),
                                React.createElement(Form.Check, { type: "radio", label: "Customize Metadata", checked: useCustom, onChange: function () { return setUseCustom(true); } })),
                            useCustom && (React.createElement(Button, { className: "mt-2 btn-sm", variant: "secondary", onClick: handleCopyFromDefault }, "Copy Default Values")),
                            React.createElement("hr", null))),
                        React.createElement(Row, null,
                            React.createElement(Col, { md: 6 },
                                React.createElement(Form.Group, { className: "mb-3" },
                                    React.createElement(Form.Label, null,
                                        React.createElement("strong", null, "Enable Shrink Wrap")),
                                    React.createElement(Form.Check, { type: "switch", id: "enabled-switch", label: values.enabled ? 'Yes' : 'No', checked: values.enabled, onChange: function () { return setFieldValue('enabled', !values.enabled); }, disabled: !isEditable })))),
                        values.enabled && (React.createElement(React.Fragment, null,
                            React.createElement(Row, null,
                                React.createElement(Col, { md: 12 },
                                    React.createElement("h5", { className: "mb-3" }, "Shrink Wrap Options"),
                                    React.createElement(ErrorMessage, { name: "shrinkWrapOptions", component: "div", className: "text-danger mb-2" }),
                                    React.createElement(Table, { bordered: true, hover: true },
                                        React.createElement("thead", null,
                                            React.createElement("tr", null,
                                                React.createElement("th", null, "Substrate Type"),
                                                React.createElement("th", null, "Increment"),
                                                React.createElement("th", null, "Min"),
                                                React.createElement("th", null, "Default"),
                                                React.createElement("th", null, "Capacity X"),
                                                React.createElement("th", null, "Capacity Y"),
                                                React.createElement("th", null, "Capacity Z"),
                                                React.createElement("th", null, "Default"),
                                                React.createElement("th", null))),
                                        React.createElement("tbody", null, values.shrinkWrapOptions.map(function (option, index) { return (React.createElement(DraggableRow, { key: index, index: index, type: "shrinkWrapOptions", option: option, setFieldValue: setFieldValue, values: values })); }))),
                                    React.createElement("div", { className: "text-end" },
                                        React.createElement(Button, { variant: "secondary", onClick: handleAddShrinkWrap, disabled: !isEditable }, "Add Substrate")))))),
                        React.createElement(Button, { type: "submit", disabled: isSubmitting, variant: "primary", className: "mt-4 px-4 py-2" }, "Save Changes"))),
                React.createElement(Modal, { show: showModal, onHide: function () { return setShowModal(false); } },
                    React.createElement(Modal.Header, { closeButton: true },
                        React.createElement(Modal.Title, null, editIndex !== null ? 'Edit Shrink Wrap' : 'Add New Shrink Wrap')),
                    React.createElement(Modal.Body, null,
                        error && (React.createElement(Alert, { variant: "danger", className: "mt-3" }, error)),
                        React.createElement(Form, null,
                            React.createElement(Form.Group, { className: "mb-3" },
                                React.createElement(Form.Label, null, "Select Substrate Type"),
                                React.createElement(Form.Control, { as: "select", value: modalFields.substrateType, onChange: function (e) { return handleSubstrateTypeChange(e.target.value); } },
                                    React.createElement("option", { value: "", disabled: true }, "Select Substrate Type"),
                                    substrateTypes.map(function (substrate) {
                                        var isDisabled = values.shrinkWrapOptions.some(function (opt, i) {
                                            return opt.substrateType === substrate.substrateType &&
                                                i !== editIndex;
                                        });
                                        return (React.createElement("option", { key: substrate.id, value: substrate.substrateType, disabled: isDisabled }, substrate.label));
                                    }))),
                            React.createElement(Form.Group, { className: "mb-3" },
                                React.createElement(Form.Label, null, "Select Color"),
                                React.createElement(Form.Control, { as: "select", value: modalFields.colour, disabled: !modalFields.substrateType, onChange: function (e) { return handleColorChange(e.target.value); } },
                                    React.createElement("option", { value: "" }, "Select Color"), (_b = substrateTypes
                                    .find(function (st) { return st.substrateType === modalFields.substrateType; })) === null || _b === void 0 ? void 0 :
                                    _b.substrateColours.map(function (color) { return (React.createElement("option", { key: color.substrateColour, value: color.substrateColour }, color.name)); }))),
                            modalFields.substrateType && modalFields.colour && (React.createElement(Form.Group, { className: "mb-3" },
                                React.createElement(Form.Label, null, "Select Weight"),
                                React.createElement(Form.Control, { as: "select", value: modalFields.weight, onChange: function (e) {
                                        return setModalFields(function (prev) { return (__assign(__assign({}, prev), { weight: e.target.value })); });
                                    } },
                                    React.createElement("option", { value: "" }, "Select Weight"),
                                    getAvailableWeights(modalFields.substrateType, modalFields.colour, substrateTypes).map(function (w) { return (React.createElement("option", { key: w.id, value: w.id.toString() },
                                        w.weight,
                                        w.weightUnit)); })))),
                            React.createElement(Row, null,
                                React.createElement(Col, { xs: 4 },
                                    React.createElement(Form.Group, { className: "mb-3", controlId: "bundleIncrement" },
                                        React.createElement(Form.Label, null, "Increment"),
                                        React.createElement(Field, { type: "number", name: "bundleIncrement", className: "form-control", value: modalFields.bundleIncrement, onChange: function (e) {
                                                return setModalFields(function (prev) { return (__assign(__assign({}, prev), { bundleIncrement: Number(e.target.value) })); });
                                            }, disabled: !isEditable }))),
                                React.createElement(Col, { xs: 4 },
                                    React.createElement(Form.Group, { className: "mb-3", controlId: "bundleMinimum" },
                                        React.createElement(Form.Label, null, "Minimum"),
                                        React.createElement(Field, { type: "number", name: "bundleMinimum", className: "form-control", value: modalFields.bundleMinimum, onChange: function (e) {
                                                return setModalFields(function (prev) { return (__assign(__assign({}, prev), { bundleMinimum: Number(e.target.value) })); });
                                            }, disabled: !isEditable }))),
                                React.createElement(Col, { xs: 4 },
                                    React.createElement(Form.Group, { className: "mb-3", controlId: "bundleDefault" },
                                        React.createElement(Form.Label, null, "Default"),
                                        React.createElement(Field, { type: "number", name: "bundleDefault", className: "form-control", value: modalFields.bundleDefault, onChange: function (e) {
                                                return setModalFields(function (prev) { return (__assign(__assign({}, prev), { bundleDefault: Number(e.target.value) })); });
                                            }, disabled: !isEditable })))),
                            React.createElement(Form.Group, { className: "mb-3" },
                                React.createElement(Form.Label, null,
                                    React.createElement("strong", null, "Capacity")),
                                React.createElement(Row, null,
                                    React.createElement(Col, { xs: 4 },
                                        React.createElement(Form.Group, { controlId: "capacityX" },
                                            React.createElement(Form.Label, null, "X"),
                                            React.createElement(Field, { type: "number", name: "capacityX", className: "form-control", value: modalFields.capacityX, onChange: function (e) {
                                                    return setModalFields(function (prev) { return (__assign(__assign({}, prev), { capacityX: Number(e.target.value) })); });
                                                }, disabled: !isEditable }))),
                                    React.createElement(Col, { xs: 4 },
                                        React.createElement(Form.Group, { controlId: "capacityY" },
                                            React.createElement(Form.Label, null, "Y"),
                                            React.createElement(Field, { type: "number", name: "capacityY", className: "form-control", value: modalFields.capacityY, onChange: function (e) {
                                                    return setModalFields(function (prev) { return (__assign(__assign({}, prev), { capacityY: Number(e.target.value) })); });
                                                }, disabled: !isEditable }))),
                                    React.createElement(Col, { xs: 4 },
                                        React.createElement(Form.Group, { controlId: "capacityZ" },
                                            React.createElement(Form.Label, null, "Z"),
                                            React.createElement(Field, { type: "number", name: "capacityZ", className: "form-control", value: modalFields.capacityZ, onChange: function (e) {
                                                    return setModalFields(function (prev) { return (__assign(__assign({}, prev), { capacityZ: Number(e.target.value) })); });
                                                }, disabled: !isEditable }))))))),
                    React.createElement(Modal.Footer, null,
                        React.createElement(Button, { variant: "secondary", onClick: function () { return setShowModal(false); } }, "Close"),
                        React.createElement(Button, { variant: "primary", onClick: function () { return handleModalSave(setFieldValue, values); } }, editIndex !== null ? 'Save Changes' : 'Add Option'))),
                React.createElement(ConfirmationModal, { show: showConfirmationModal, onHide: function () { return setShowConfirmationModal(false); }, onConfirm: function () {
                        setShowConfirmationModal(false);
                        saveShrinkWrapsMetadata(values, setSubmitting);
                    } })));
        })));
};
export default ShrinkWrapMetaDataEditor;
