import defineComponent from '../../../../components/flight/lib/component';
import DataTable from './../../table/data-table-net/data-table-net.tsx';
import axios from "axios";
import {CELL_TYPES as CellTypes} from "../../table/data-table-net/DataTableConstants.ts";
import mixam from "../../../boot/mixam";

export default defineComponent(MessagesDataTable);

function MessagesDataTable() {

    this.attributes({
        url: "/api/admin/messages",
        dataTableSelector: '[data-type="data-table"]'
    });

    this.createSchema = function () {
        const cols = [];
        cols.push({
            title: "Key",
            type: "text",
            data: "key",
            defaultSort: true
        });
        cols.push({
            title: "Application",
            type: "text",
            data: "application"
        });
        cols.push({
            title: "Default Message",
            type: "text",
            data: "defaultMessage",
            width: "25%",
        });
        cols.push({
            title: "Locale Overrides",
            type: "text",
            data: "locales"
        });
        cols.push({
            title: "Usage Count",
            type: "number",
            data: "usageCount"
        });
        cols.push({
            title: "Last Modified",
            type: "timebox",
            data: "lastModifiedDateTimestamp"
        });
        cols.push({
            title: "Last Modified By",
            type: "text",
            data: "lastModifiedBy"
        });
        cols.push({
            title: "",
            type: CellTypes.MESSAGES_EDITOR,
            data: "editorData",
            width: '175px'
        });
        return cols;
    };


    this.paint = function () {
        const data = {
            columns: this.createSchema(),
            fileName: this.attr.url.split("/").pop(),
            response: this.normalize(this.data)
        };

        if (this.dataReadyFired) {
            window.dispatchEvent(new CustomEvent('uiDataUpdate', { detail: data }));
        } else {
            this.dataReadyFired = true;
            this.trigger("uiDataReady", data);
        }
    };

    this.normalize = function (list) {
        return list.map(message => this.normalizeMessage(message));
    };

    this.normalizeMessage = function (message_) {
        const message = $.extend(true, {}, message_);
        message.locales = message.localeMessages ? Object.keys(message.localeMessages).join(', ') : '';
        message.actions = [
            {
                href: `/messages/${message.id}`,
                caption: "Edit",
                target: "_self",
                className: "btn btn-primary"
            }
        ];
        message.editorData = message;
        return message;
    };

    this.subscribe = function (onMessage) {
        this.trigger("log", {message: ["Subscribe to stomp channel allmessageschannel"], title: "Messages.subscribe"});
        this.stomp = Stomp.client(mixam.stompServiceUrl);

        this.stomp.debug = (...args) => {
            if (args.join('').indexOf('Whoops! Lost connection to') !== -1) {
                setTimeout(() => this.subscribe(onMessage), 10);
            }
            this.trigger("log", {message: args, title: "Messages.subscribe"});
        };

        this.stomp.connect(mixam.stompWebUser, mixam.stompWebPass, () => {
            this.stomp.subscribe("/topic/allmessageschannel", (d) => {
                const p = JSON.parse(d.body);
                onMessage(p);
            });
        }, (err) => this.trigger("log", err), '/');
    };

    this.updateMessageLine = function (data) {
        if (!this.data) {
            return;
        }
        let verb = data.type;
        const message = data.data;
        const index = this.data.map(x => x.id).indexOf(message.id);
        if (verb === "UPDATE" || verb === "INSERT") {
            if (index !== -1) {
                this.data[index] = message;
            } else {
                this.data.unshift(message);
            }

        } else if (verb === "DELETE" && index !== -1) {
            this.data.splice(index, 1);
        }

        this.paint();
    };

    this.getData = function () {
        axios.get(this.attr.url)
            .then(response => {
                this.data = response.data;
                this.paint();
                setTimeout(() => this.getData(), 1000 * 60 * 15);
            }).catch(error => this.trigger("log", {message: error}));
    };

    this.after('initialize', function () {
        DataTable.attachTo(this.select('dataTableSelector'));
        setTimeout(() => this.getData(), 10);
        this.subscribe(d => this.updateMessageLine(d));
    });
}
